import React, { createContext, useContext, useState } from 'react';
import { useSubscription } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import {
    GRAPH_SUBSCRIPTION,
    RECOMMENDED_TECH_STACK_STREAM
} from '../api/graphQL';
import {
    findFreeNodeLabel,
    selectFreeNodeLabel,
    setGraph,
    setGraphVersion,
    setSelectedNode
} from '../redux/reducers/generic/projectArchitecture';
import {
    addToGraphVersions,
    addToTechStackVersions,
    setRecommendedTechStack,
    setTechStackVersion
} from '../redux/reducers/generic/project';
import { useProject } from './Project';
import { getGraphData } from '../helpers/graph';

const GraphContext = createContext();

export const GraphProvider = ({ children }) => {
    const { allSearchParams, setSearchParams, chatHistory } = useProject();
    const dispatch = useDispatch();
    let { projectId } = useProject();

    useSubscription(RECOMMENDED_TECH_STACK_STREAM, {
        variables: { projectId: projectId || '' },
        shouldResubscribe: true,
        onData: data => {
            try {
                const recommended_teck_stack =
                    data.data.data.recommendedTechStackStream;

                dispatch(
                    addToTechStackVersions(recommended_teck_stack.version)
                );
                dispatch(setTechStackVersion(recommended_teck_stack.version));
                dispatch(setRecommendedTechStack(recommended_teck_stack.stack));
            } catch (error) {
                console.log(error, data);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
    });

    const moveToCodePage = ({ graph, freeNodeLabel }) => {
        console.log({
            graph,
            freeNodeLabel,
            graphlength: graph?.nodes?.length,
            chatLength: chatHistory.data.length
        });

        // If the graph is frontend, set search params to the code page
        if (
            graph.side === 'frontend' &&
            graph?.nodes?.length > 1 &&
            allSearchParams.pageType !== 'code' &&
            chatHistory.data.length == 3 &&
            freeNodeLabel
        ) {
      
            setSearchParams({
                ...allSearchParams,
                pageType: 'code',
                graphType: 'frontend'
            });
            dispatch(
                setSelectedNode(
                    graph.nodes.find(n => n.label === freeNodeLabel)
                )
            );
        }
        // If the graph is user flow, set search params to the user flow page
        // if (graph.side === 'user flow' && graph?.nodes?.length > 1 && allSearchParams.pageType !== 'chat') {
        //     setSearchParams({
        //         ...allSearchParams,
        //         pageType: 'chat',
        //         graphType: 'user flow'
        //     });
        // }
    };

    useSubscription(GRAPH_SUBSCRIPTION, {
        variables: { projectId: projectId || '' },
        shouldResubscribe: true,
        onData: data => {
            try {
                const graph = data.data.data.graphStream;
                console.log(`graph version: ${graph.version} - ${graph.side}`);
                if (graph.projectId != projectId) return;
                dispatch(setGraphVersion(graph.version));
                dispatch(addToGraphVersions(graph.version));
                dispatch(setGraph(graph));

                try {
                    setTimeout(() => {
                        const freeNodeLabel = findFreeNodeLabel([
                            getGraphData(graph)
                        ]);

                        moveToCodePage({ graph, freeNodeLabel });
                    }, 3000);
                } catch (error) {
                    console.log(error);
                }

                console.log(graph);
            } catch (error) {
                console.log(error);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
        }
    });

    return (
        <GraphContext.Provider value={true}>{children}</GraphContext.Provider>
    );
};

export const useGraph = () => useContext(GraphContext);
