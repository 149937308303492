import { useDispatch } from 'react-redux';
import { client } from '../../../../../api/client';
import { INITIATE_SUGGESTION } from '../../../../../api/graphQL';
import { waitingForSuggestion } from '../../../../../redux/reducers/generic/chatHistory';
import { useProject } from '../../../../../context-providers/Project';
import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import trackEvent from '../../../../../helpers/trackEvent';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

export const Suggest = ({ answer, suggestion, setSuggestion }) => {
    let { chatHistory, projectId, project } = useProject();
    let waiting =
        chatHistory.waitingForChatResponse || chatHistory.waitingForSuggestion;

    let [randId, setRandId] = useState('');
    const dispatch = useDispatch();
    const { accessTokenPayload } = useSessionContext();
    const [isSuggesting, setIsSuggesting] = useState(false);

    const handleSuggest = async () => {
        if (waiting || isSuggesting) return;
        setIsSuggesting(true);
        dispatch(waitingForSuggestion(true));
        try {
            let randId = uuid();
            setRandId(randId);
            trackEvent('auto_suggestion', {
                userEmail: project?.userEmail,
                userId: accessTokenPayload?.userId
            });
            let data = await client.mutate({
                mutation: INITIATE_SUGGESTION,
                variables: { projectId, randId }
            });
            dispatch(waitingForSuggestion(false));
            setIsSuggesting(false);
            let suggestion = data.data.initiateSuggestionStream.suggestion;
            setSuggestion(suggestion.trim());
        } catch (error) {
            console.log(error);
            dispatch(waitingForSuggestion(false));
            setIsSuggesting(false);
        }
    };

    // Generate a suggestion when the chat loads
    useEffect(() => {
        if (!chatHistory.waitingForChatResponse && !answer && !suggestion) {
            handleSuggest();
        }
    }, [
        chatHistory.waitingForChatResponse,
        chatHistory.data.length,
        answer,
        suggestion
    ]);

    // Generate a new suggestion after user sends a message
    useEffect(() => {
        if (!waiting && chatHistory.data.length > 0 && !answer && !suggestion) {
            const lastMessage = chatHistory.data[chatHistory.data.length - 1];
            if (lastMessage && lastMessage.role === 'assistant') {
                handleSuggest();
            }
        }
    }, [waiting, chatHistory.data]);

    return null;
};
