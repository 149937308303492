/**
 * A collection of utility functions inspired by lodash
 */

/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 * @param {*} value - The value to compare.
 * @param {*} other - The other value to compare.
 * @returns {boolean} Returns true if the values are equivalent, else false.
 */
export function isEqual(value, other) {
    // Handle simple cases first
    if (value === other) return true;

    // If either is null or not an object, and we've already checked equality, they're not equal
    if (
        value === null ||
        other === null ||
        typeof value !== 'object' ||
        typeof other !== 'object'
    ) {
        return false;
    }

    // Handle Date objects
    if (value instanceof Date && other instanceof Date) {
        return value.getTime() === other.getTime();
    }

    // Handle RegExp objects
    if (value instanceof RegExp && other instanceof RegExp) {
        return value.toString() === other.toString();
    }

    // Handle Arrays
    if (Array.isArray(value) && Array.isArray(other)) {
        if (value.length !== other.length) return false;

        for (let i = 0; i < value.length; i++) {
            if (!isEqual(value[i], other[i])) return false;
        }

        return true;
    }

    // Handle Objects
    if (!Array.isArray(value) && !Array.isArray(other)) {
        const valueKeys = Object.keys(value);
        const otherKeys = Object.keys(other);

        if (valueKeys.length !== otherKeys.length) return false;

        // Check if every key in value exists in other and has the same value
        return valueKeys.every(
            key =>
                Object.prototype.hasOwnProperty.call(other, key) &&
                isEqual(value[key], other[key])
        );
    }

    // If we get here, they're not equal
    return false;
}
/**
 * Creates a debounced function that delays invoking the provided function
 * until after the specified wait time has elapsed since the last time it was invoked.
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay.
 * @param {Object} [options={}] - The options object.
 * @param {boolean} [options.leading=false] - Specify invoking on the leading edge of the timeout.
 * @param {boolean} [options.trailing=true] - Specify invoking on the trailing edge of the timeout.
 * @returns {Function} Returns the new debounced function.
 */
export function debounce(func, wait = 0, options = {}) {
    let timeout;
    let result;
    let lastArgs;
    let lastThis;
    let lastCallTime = 0;
    let lastInvokeTime = 0;
    const leading = !!options.leading;
    const trailing = 'trailing' in options ? !!options.trailing : true;
    const maxWait =
        'maxWait' in options ? Math.max(options.maxWait, wait) : null;

    function invokeFunc(time) {
        lastInvokeTime = time;
        result = func.apply(lastThis, lastArgs);
        lastArgs = lastThis = null;
        return result;
    }

    function shouldInvoke(time) {
        const timeSinceLastCall = time - lastCallTime;
        const timeSinceLastInvoke = time - lastInvokeTime;

        // Either this is the first call, wait time has elapsed, or max wait time has elapsed
        return (
            lastCallTime === 0 ||
            timeSinceLastCall >= wait ||
            (maxWait !== null && timeSinceLastInvoke >= maxWait)
        );
    }

    function trailingEdge(time) {
        timeout = undefined;

        if (trailing && lastArgs) {
            return invokeFunc(time);
        }

        lastArgs = lastThis = null;
        return result;
    }

    function remainingWait(time) {
        const timeSinceLastCall = time - lastCallTime;
        const timeSinceLastInvoke = time - lastInvokeTime;
        const timeWaiting = wait - timeSinceLastCall;

        return maxWait === null
            ? timeWaiting
            : Math.min(timeWaiting, maxWait - timeSinceLastInvoke);
    }

    function debounced(...args) {
        const time = Date.now();
        const isInvoking = shouldInvoke(time);

        lastArgs = args;
        lastThis = this;
        lastCallTime = time;

        if (isInvoking) {
            if (timeout === undefined) {
                if (leading) {
                    lastInvokeTime = lastCallTime;
                    result = func.apply(lastThis, lastArgs);
                }

                if (!leading) {
                    timeout = setTimeout(() => trailingEdge(Date.now()), wait);
                } else {
                    timeout = setTimeout(
                        () => trailingEdge(Date.now()),
                        remainingWait(time)
                    );
                }

                return result;
            }

            if (maxWait !== null) {
                timeout = setTimeout(
                    () => trailingEdge(Date.now()),
                    remainingWait(time)
                );
            }

            return result;
        }

        if (timeout === undefined) {
            timeout = setTimeout(
                () => trailingEdge(Date.now()),
                remainingWait(time)
            );
        }

        return result;
    }

    debounced.cancel = function () {
        if (timeout !== undefined) {
            clearTimeout(timeout);
        }
        lastInvokeTime = 0;
        lastArgs = lastCallTime = lastThis = timeout = undefined;
    };

    debounced.flush = function () {
        return timeout === undefined ? result : trailingEdge(Date.now());
    };

    return debounced;
}

/**
 * Creates a deep clone of the provided value.
 * @param {*} value - The value to clone.
 * @returns {*} Returns the deep cloned value.
 */
export function cloneDeep(value) {
    // Handle primitive types and null
    if (value === null || typeof value !== 'object') {
        return value;
    }

    // Handle Date objects
    if (value instanceof Date) {
        return new Date(value.getTime());
    }

    // Handle RegExp objects
    if (value instanceof RegExp) {
        return new RegExp(value.source, value.flags);
    }

    // Handle Arrays
    if (Array.isArray(value)) {
        return value.map(item => cloneDeep(item));
    }

    // Handle Objects
    const cloned = {};
    for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
            cloned[key] = cloneDeep(value[key]);
        }
    }

    return cloned;
}
