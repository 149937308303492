import { PhoneVerification } from './PhoneVerification';

export const WidgetFields = ({
    email,
    setEmail,
    requirePhoneNumber,
    phoneNumber,
    isPhoneVerified,
    setIsPhoneVerified,
    setPhoneNumber,
    businessURL,
    setBusinessURL,
    requireBusinessURL
}) => {
    function validateURL(url) {
        try {
            new URL(url);
            return true;
        } catch {
            return false;
        }
    }

    return (
        <>
            <input
                id="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="border border-gray-300 rounded-md px-4 py-2 w-full outline-none mb-2 bg-[var(--widget-background-color,#FFFFFF)] placeholder:text-[var(--widget-text-color,#111827)] shadow-sm transition-all duration-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 focus:shadow-md hover:border-gray-400"
            />
            {requireBusinessURL && (
                <input
                    id="businessURL"
                    type="url"
                    placeholder="Business URL (e.g. https://example.com)"
                    value={businessURL}
                    onChange={e => {
                        const url = e.target.value;
                        setBusinessURL(url);
                    }}
                    pattern="https?://.*"
                    className="border border-gray-300 rounded-md px-4 py-2 w-full outline-none mb-2 bg-[var(--widget-background-color,#FFFFFF)] placeholder:text-[var(--widget-text-color,#111827)] shadow-sm transition-all duration-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 focus:shadow-md hover:border-gray-400"
                />
            )}
            {requirePhoneNumber && (
                <PhoneVerification
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    setIsPhoneVerified={setIsPhoneVerified}
                    isPhoneVerified={isPhoneVerified}
                />
            )}
        </>
    );
};
