import projectReducer from './reducers/generic/project';
import projectsReducer from './reducers/generic/projects';
import chatHistoryReducer from './reducers/generic/chatHistory';
import projectArchitectureReducer, {
    projectArchitectureListenerMiddleware
} from './reducers/generic/projectArchitecture';
import billingReducer from './reducers/generic/billing';
import enterpriseOrganizationsReducer from './reducers/enterprise/enterpriseOrganizations';
import enterpriseOrganizationReducer from './reducers/enterprise/enterpriseOrganization';
import enterpriseWidgetReducer from './reducers/enterprise_widget/enterpriseWidget';
import clientOnboardingReducer from './reducers/enterprise/clientOnboarding';
import enterpriseUsersReducer from './reducers/enterprise/enterpriseUsers';
import chatHistoryUIViewReducer from './reducers/generic/chatHistoryUIView';
import jiraReducer from './reducers/generic/jira';
import sidebarReducer from './reducers/generic/sidebar';
import downloadCursorReducer from './reducers/generic/downloadCursor';

import { configureStore } from '@reduxjs/toolkit';
import graphEditsReducer from './reducers/generic/graphEdits';
import leaderboardReducer from './reducers/leaderboard';
import linearReducer from './reducers/generic/linear';
import profileReducer from './reducers/generic/profile';
import devAgenciesReducer from './reducers/generic/devAgencies';
import landingReducer from './reducers/generic/landing';

export const store = configureStore({
    reducer: {
        profile: profileReducer,
        leaderboard: leaderboardReducer,
        sidebar: sidebarReducer,
        jira: jiraReducer,
        linear: linearReducer,
        billing: billingReducer,
        project: projectReducer,
        projects: projectsReducer,
        chatHistory: chatHistoryReducer,
        projectArchitecture: projectArchitectureReducer,
        enterpriseOrganizations: enterpriseOrganizationsReducer,
        enterpriseOrganization: enterpriseOrganizationReducer,
        enterpriseWidget: enterpriseWidgetReducer,
        clientOnboarding: clientOnboardingReducer,
        enterpriseUsers: enterpriseUsersReducer,
        graphEdits: graphEditsReducer,
        chatHistoryUIView: chatHistoryUIViewReducer,
        devAgencies: devAgenciesReducer,
        landing: landingReducer,
        downloadCursor: downloadCursorReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().prepend(
            projectArchitectureListenerMiddleware.middleware
        )
});
