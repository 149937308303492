import { Button } from '../Components/magicui/ui/button';
import { cn } from '../Components/magicui/lib/utils';
import { CheckIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectAddOn,
    selectEnterpriseOrganization,
    setAddOn
} from '../../redux/reducers/enterprise/enterpriseOrganization';
import { createEnterpriseStripeLinkWithQuantityAndDuration } from '../../redux/actions/enterprise/createEnterpriseStripeLinkWithQuantityAndDuration';
import { toast } from 'sonner';
import { salesCalendlyLink } from '../../constants';
import DiscountOfferModal from '../Components/DiscountOfferModal';
import trackEvent from '../../helpers/trackEvent';

type Interval = 'monthly' | 'yearly';

const toHumanPrice = (price: number): string => {
    const convertedPrice = Math.floor(price / 100);
    return Number.isInteger(convertedPrice)
        ? convertedPrice?.toString()
        : convertedPrice?.toFixed(0);
};

interface PriceCardProps {
    price: {
        id: string;
        name: string;
        description: string;
        features: string[];
        addons: string[];
        stripeAddOns?: any;
        monthlyPrice: number;
        halfOffFirstMonth?: boolean;
        yearlyPrice: number;
        premiumFeatures?: any;
        textColor: string;
        perUser?: boolean;
        prototypeGenerations?: {
            frequency: string;
            quantity: string;
        };
    };
    interval: Interval;
    isInView: boolean;
    idx: number;
    freeTrialActivated: boolean;
    subscriptionLinks: any;
    type: string;
}

export default function PriceCard({
    price,
    interval,
    isInView,
    idx,
    freeTrialActivated,
    subscriptionLinks,
    type
}: PriceCardProps) {
    const [seats, setSeats] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const addOn =
        price.id === 'LeadGenPro'
            ? 'LeadGenPro'
            : price.id === 'LeadGenBasic'
              ? 'LeadGenBasic'
              : null;
    let enterpriseOrganizationId = enterpriseOrganization?._id?.toString();

    const getLink = (priceId: string): string => {
        if (priceId === 'beta') {
            return 'https://buy.stripe.com/7sI17Jc4pe1gddSaDa';
        } else {
            const duration = interval === 'yearly' ? 'Yearly' : 'Monthly';
            if (subscriptionLinks) {
                if (priceId === 'enterprise' && type !== 'enterprise') {
                    return '/enterprise/dashboard';
                } else {
                    let sub = subscriptionLinks.find(
                        (link: any) =>
                            link.tier === priceId &&
                            link.duration.toLowerCase() ===
                                duration.toLowerCase() &&
                            link.trial === freeTrialActivated
                    );
                    const link = sub?.url;
                    return link;
                }
            } else {
                return '/auth';
            }
        }
    };

    const handleSelectPlan = async () => {
        setIsLoading(true);
        try {
            if (seats <= 0) {
                toast.error('Number of seats must be greater than 0');
            }
            let stripeLink =
                await createEnterpriseStripeLinkWithQuantityAndDuration({
                    enterpriseOrganizationId,
                    duration: interval,
                    quantity: seats,
                    addOns: addOn ? [addOn] : []
                });
            if (addOn && price.halfOffFirstMonth)
                stripeLink = stripeLink + '?prefilled_promo_code=AgencyDeluxe';
            if (stripeLink) {
                window.open(stripeLink, '_blank', 'noopener,noreferrer');
            } else {
                throw new Error('Failed to generate Stripe link');
            }
        } catch (error) {
            console.error('Error creating Stripe link:', error);
            // Handle error (e.g., show error message to user)
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <motion.div
            key={price.id}
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{
                duration: 1,
                delay: 0.1 + idx * 0.05,
                ease: [0.21, 0.47, 0.32, 0.98]
            }}
            className={cn(
                `relative grid grid-cols-1 gap-8 rounded-2xl border p-4 text-black dark:text-white overflow-hidden`,
                'hover:border-2 hover:border-[var(--color-one)]'
            )}
        >
            <div className="flex flex-col gap-y-4 w-full">
                <TitleAndDescription price={price} />
                {freeTrialActivated && (
                    <p className="-mb-4 text-sm">
                        <strong>7 days free</strong>, then
                    </p>
                )}
                <Price price={price} interval={interval} />
                {price.halfOffFirstMonth && (
                    <div className="bg-gradient-to-r from-yellow-100 to-yellow-200 dark:from-yellow-900 dark:to-yellow-800 p-3 rounded-lg shadow-sm -mt-2 mb-2">
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col">
                                <span className="text-sm font-bold text-yellow-800 dark:text-yellow-300">
                                    50% OFF FIRST MONTH
                                </span>
                                <span className="text-xs text-yellow-700 dark:text-yellow-400 mt-1">
                                    First month: $
                                    {interval === 'yearly'
                                        ? toHumanPrice(
                                              price.yearlyPrice / 12 / 2
                                          )
                                        : toHumanPrice(price.monthlyPrice / 2)}
                                </span>
                                <span className="text-xs text-yellow-700 dark:text-yellow-400">
                                    Then: $
                                    {interval === 'yearly'
                                        ? toHumanPrice(price.yearlyPrice / 12)
                                        : toHumanPrice(price.monthlyPrice)}
                                    {price.perUser ? ' per seat' : ''}/month
                                </span>
                            </div>
                            <div className="bg-yellow-500 dark:bg-yellow-600 text-white text-xs font-bold px-2 py-1 rounded-full transform rotate-3">
                                SAVE 50%
                            </div>
                        </div>
                    </div>
                )}
                {(price.id === 'EnterprisePremium' ||
                    price.id === 'LeadGenPro' ||
                    price.id === 'LeadGenBasic') &&
                subscriptionLinks?.length > 0 ? (
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <label htmlFor="seats" className="text-sm">
                                Number of seats:
                            </label>
                            <input
                                type="number"
                                id="seats"
                                min="1"
                                value={seats}
                                onChange={e =>
                                    setSeats(parseInt(e.target.value))
                                }
                                className="w-16 px-2 py-1 border rounded text-black"
                            />
                        </div>
                        <Button
                            onClick={handleSelectPlan}
                            disabled={isLoading}
                            className={cn(
                                'group relative w-full text-black gap-2 overflow-hidden text-lg font-semibold tracking-tighter',
                                'transform-gpu ring-offset-current transition-all duration-300 ease-out hover:ring-2 hover:ring-primary hover:ring-offset-2'
                            )}
                        >
                            <span className="absolute right-0 h-32 w-8 translate-x-12 rotate-12 transform-gpu bg-white opacity-10 transition-all duration-1000 ease-out group-hover:-translate-x-96 dark:bg-black" />
                            {isLoading
                                ? 'Loading...'
                                : freeTrialActivated
                                  ? 'Start Free Trial'
                                  : 'Select Plan'}
                        </Button>
                    </div>
                ) : (
                    subscriptionLinks?.length > 0 && (
                        <Link to={getLink(price.id)}>
                            <Button
                                className={cn(
                                    'group relative w-full text-black gap-2 overflow-hidden text-lg font-semibold tracking-tighter',
                                    'transform-gpu ring-offset-current transition-all duration-300 ease-out hover:ring-2 hover:ring-primary hover:ring-offset-2'
                                )}
                            >
                                <span className="absolute right-0 h-32 w-8 translate-x-12 rotate-12 transform-gpu bg-white opacity-10 transition-all duration-1000 ease-out group-hover:-translate-x-96 dark:bg-black" />
                                {type === 'enterprise' || type === 'solo' ? (
                                    <p>
                                        {freeTrialActivated
                                            ? 'Start Free Trial'
                                            : 'Select Plan'}
                                    </p>
                                ) : (
                                    <p>Sign Up</p>
                                )}
                            </Button>
                        </Link>
                    )
                )}
                <hr className="m-0 h-px w-full border-none bg-gradient-to-r from-neutral-200/0 via-neutral-500/30 to-neutral-200/0" />
                <div className="flex gap-2 flex-row">
                    <div className="flex flex-col gap-y-4 w-full">
                        <PrototypeGenerations price={price} />
                        <Features
                            price={price}
                            subscriptionLinks={subscriptionLinks}
                        />
                        <PremiumFeatures price={price} />
                    </div>
                </div>

                <ScheduleMeeting price={price} />
                {(!subscriptionLinks || subscriptionLinks?.length === 0) && (
                    <div className="flex flex-col items-center justify-center">
                        <Link
                            to={
                                [
                                    'EnterprisePremium',
                                    'LeadGenPro',
                                    'LeadGenBasic'
                                ].includes(price.id)
                                    ? '/enterprise/dashboard'
                                    : '/projects'
                            }
                            onClick={() => {
                                trackEvent(
                                    price.id + '_get_started_click_pricing'
                                );
                            }}
                            className="w-full"
                        >
                            <Button className="group relative w-full text-black gap-2 overflow-hidden text-lg font-semibold tracking-tighter transform-gpu ring-offset-current transition-all duration-300 ease-out hover:ring-2 hover:ring-primary hover:ring-offset-2">
                                <span className="absolute right-0 h-32 w-8 translate-x-12 rotate-12 transform-gpu bg-white opacity-10 transition-all duration-1000 ease-out group-hover:-translate-x-96 dark:bg-black" />
                                Sign Up
                            </Button>
                        </Link>
                    </div>
                )}
            </div>
        </motion.div>
    );
}

export const PrototypeGenerations = ({ price }) => {
    if (!price.prototypeGenerations) return null;

    return (
        <div className="flex items-center gap-2">
            <span className="text-xl font-bold bg-gradient-to-br from-indigo-300 to-blue-200 bg-clip-text text-transparent">
                {price.prototypeGenerations.quantity}{' '}
                <span className="text-sm text-indigo-400">
                    Prototype Generations
                </span>
                {price.prototypeGenerations.frequency && (
                    <span className="text-sm font-medium text-indigo-400">
                        {' '}
                        / {price.prototypeGenerations.frequency}
                    </span>
                )}
            </span>
        </div>
    );
};

//
export const Price = ({ price, interval }) => {
    return (
        <motion.div
            key={`${price.id}-${interval}`}
            initial="initial"
            animate="animate"
            variants={{
                initial: { opacity: 0, y: 12 },
                animate: { opacity: 1, y: 0 }
            }}
            transition={{
                duration: 0.4,
                delay: 0.1,
                ease: [0.21, 0.47, 0.32, 0.98]
            }}
            className="flex flex-col gap-1"
        >
            <span className="text-4xl font-bold text-black dark:text-white">
                $
                {interval === 'yearly'
                    ? toHumanPrice(price.yearlyPrice / 12)
                    : toHumanPrice(price.monthlyPrice)}
                <span className="text-sm">
                    {' '}
                    / month{price.perUser ? ' per seat' : ''}
                </span>
            </span>
            {interval === 'yearly' && (
                <span className="text-sm block mt-1 text-gray-400">
                    Billed annually
                </span>
            )}
        </motion.div>
    );
};

export const TitleAndDescription = ({ price }) => {
    return (
        <div className="flex items-center">
            <div className="">
                <h2
                    className={`text-base font-semibold leading-7 ${price.textColor}`}
                >
                    {price.name}
                </h2>
                <p className="text-sm leading-5 text-black/70 dark:text-white">
                    {price.description}
                </p>
            </div>
        </div>
    );
};

export const Features = ({ price, subscriptionLinks }) => {
    return (
        <>
            {price.features && price.features.length > 0 && (
                <ul className="flex flex-col gap-1 font-normal">
                    {price.features.map((feature: string, idx: number) => (
                        <li
                            key={idx}
                            className="flex items-center gap-2 text-sm font-medium text-black dark:text-white leading-relaxed"
                        >
                            <CheckIcon className="h-4 w-4 shrink-0 rounded-full bg-green-400 p-[2px] text-black dark:text-white" />
                            <span className="flex">{feature}</span>
                        </li>
                    ))}
                </ul>
            )}
            {price.addons &&
                price.addons.length > 0 &&
                (!subscriptionLinks || subscriptionLinks?.length === 0) && (
                    <div className="">
                        <h3 className="text-sm font-semibold mb-2">
                            Special Add-ons:
                        </h3>
                        <ul className="flex flex-col gap-1 font-normal">
                            {price.addons.map((addon: string, idx: number) => (
                                <li
                                    key={idx}
                                    className="flex items-center gap-2 text-sm font-medium text-black dark:text-white leading-relaxed"
                                >
                                    <CheckIcon className="h-4 w-4 shrink-0 rounded-full bg-blue-400 p-[2px] text-black dark:text-white" />
                                    <span className="flex">{addon}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
        </>
    );
};

export const PremiumFeatures = ({ price }) => {
    if (!price.premiumFeatures || price.premiumFeatures.length === 0)
        return null;

    return (
        <div className="mt-4">
            <h3 className="text-sm font-semibold mb-2">Premium Features:</h3>
            <ul className="flex flex-col gap-3">
                {price.premiumFeatures.map((feature, idx) => (
                    <li
                        key={idx}
                        className="flex items-start gap-2 text-sm text-black dark:text-white"
                    >
                        <div className="mt-0.5">
                            {feature.icon ? (
                                feature.icon('text-blue-400')
                            ) : (
                                <CheckIcon
                                    className={`h-4 w-4 shrink-0 rounded-full ${price.textColor} p-[2px] text-black dark:text-white`}
                                />
                            )}
                        </div>
                        <div>
                            <span className="font-medium">{feature.title}</span>
                            {feature.description && (
                                <p className="text-xs text-gray-300 mt-0.5">
                                    {feature.description}
                                </p>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const ScheduleMeeting = ({ price }) => {
    if (price.id !== 'EnterprisePremium') return null;
    return (
        <div className="text-center border-t border-gray-200 dark:border-gray-700 py-2 px-6 text-sm">
            <p className="text-md font-semibold text-gray-800 dark:text-gray-200">
                Need more than 10 seats or want to use the special add-ons?{' '}
                <br />
                <a
                    href={salesCalendlyLink}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600 underline hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600 transition-colors duration-300"
                >
                    Contact us
                </a>
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                Schedule a meeting to discuss custom plans and pricing.
            </p>
        </div>
    );
};
