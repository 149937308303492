import { client } from '../../../api/client';
import { TRACK_AGENCY_INTERACTION } from '../../../api/graphQL/solo/mutator';

interface TrackAgencyInteractionParams {
    enterpriseOrganizationId: string;
    enterpriseOrganizationName: string;
    enterpriseOrganizationWebsiteURL: string;
    interactionType: string;
    userId?: string;
    userEmail?: string;
}

export const trackAgencyInteraction = async (
    params: TrackAgencyInteractionParams
) => {
    try {
        const {
            enterpriseOrganizationId,
            enterpriseOrganizationName,
            enterpriseOrganizationWebsiteURL,
            interactionType
        } = params;

        // Send data to GraphQL API
        await client.mutate({
            mutation: TRACK_AGENCY_INTERACTION,
            variables: {
                enterpriseOrganizationId,
                enterpriseOrganizationName,
                enterpriseOrganizationWebsiteURL,
                interactionType
            }
        });

        return true;
    } catch (error) {
        console.error('Error tracking agency interaction:', error);
        return false;
    }
};
