import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';

interface TrustedByProps {
    isInView: boolean;
}
interface PartnerLogoProps {
    src: string;
    alt: string;
    href: string;
}
function PartnerLogo({ src, alt, href }: PartnerLogoProps) {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white/5 backdrop-blur-sm rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow duration-200 block"
        >
            <img
                alt={alt}
                src={src}
                width={158}
                height={48}
                className="max-h-12 w-full object-contain"
            />
        </a>
    );
}

const partners = [
    {
        src: '/google.png',
        alt: 'Google Cloud Partners',
        href: 'https://google.com'
    },
    { src: '/holycode.png', alt: 'HolyCode', href: 'https://holycode.com' },
    { src: '/manatech.png', alt: 'Manatech', href: 'https://manatech.com' },
    { src: '/vm.svg', alt: 'venture miami', href: 'https://venturemiami.com' },
    { src: '/cyph.png', alt: 'Wharton cypher', href: 'https://wharton.com' },
    { src: '/polkadot.png', alt: 'Polkadot', href: 'https://polkadot.com' },
    { src: '/moonbeam.png', alt: 'Moonbeam' }
];
function TrustedBy({ isInView }: TrustedByProps) {
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 1 }}
        >
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <h2 className="text-center text-2xl font-semibold text-white">
                    Trusted By Startup Ecosystems Worldwide
                </h2>
                <div className="mx-auto mt-10 max-w-lg sm:max-w-xl lg:mx-0 lg:max-w-none">
                    <div className="flex flex-wrap justify-center items-center gap-6 md:gap-8">
                        {partners.map(partner => (
                            <div
                                key={partner.alt}
                                className="flex-grow-0 flex-shrink-0 basis-[calc(50%-12px)] sm:basis-[calc(33.333%-16px)] md:basis-[calc(25%-24px)]"
                            >
                                <PartnerLogo
                                    src={partner.src}
                                    alt={partner.alt}
                                    href={partner.href}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

interface StatsProps {
    isInView: boolean;
}

function Stats({ isInView }: StatsProps) {
    const stats = [
        // { id: 1, value: '10,000+', name: 'Projects Created' },
        // { id: 2, value: '100+', name: 'Hours Saved Per Project' },
        // { id: 3, value: '100+', name: 'Developers Available To Help' }
    ];

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 1 }}
        >
            <div className="bg-transparent py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                        {stats.map(stat => (
                            <div
                                key={stat.id}
                                className="mx-auto flex max-w-xs flex-col gap-y-4"
                            >
                                <dt className="text-base/7 text-gray-400">
                                    {stat.name}
                                </dt>
                                <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                                    {stat.value}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </motion.div>
    );
}

export default function PartnerSection() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: '-100px' });

    return (
        <div ref={ref} className="bg-transparent pb-32">
            <TrustedBy isInView={isInView} />
            <Stats isInView={isInView} />
        </div>
    );
}
