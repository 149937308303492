import { motion, useInView } from 'framer-motion';
import { useState, useRef, useEffect } from 'react';
import { freeTrialActivated } from '../../constants';
import { prices } from './prices';
import PriceCard from './price-card';
import { PricingHeader } from './pricing-header';
import { IntervalSwitch } from './interval-switch';
import { useSelector } from 'react-redux';
import { selectAddOn } from '../../redux/reducers/enterprise/enterpriseOrganization';

type Interval = 'monthly' | 'yearly';

interface PricingSectionProps {
    subscriptionLinks: any;
    type: string;
}

export default function PricingSection({
    subscriptionLinks,
    type
}: PricingSectionProps) {
    const [interval, setInterval] = useState<Interval>('monthly');
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: '-100px' });
    const numPlans = type === 'enterprise' ? 3 : type === 'solo' ? 2 : 4;
    const addOn = useSelector(selectAddOn);

    useEffect(() => {
        if (addOn) {
            setInterval('monthly');
        }
    }, [addOn]);
    const gridClass = `grid gap-4 ${
        numPlans === 4
            ? 'lg:grid-cols-4 md:grid-cols-2'
            : numPlans === 3
              ? 'lg:grid-cols-3'
              : 'lg:grid-cols-2'
    }`;

    return (
        <section id="pricing" ref={ref}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                transition={{ duration: 1 }}
                className="mx-auto flex w-full flex-col gap-8 px-4 py-8 md:px-8"
            >
                <PricingHeader freeTrialActivated={freeTrialActivated} />
                <IntervalSwitch
                    interval={interval}
                    setInterval={setInterval}
                    freeTrialActivated={freeTrialActivated}
                />

                <div className={gridClass}>
                    {prices({
                        basic: !type,
                        soloPlus: !type || type === 'solo',
                        soloPremium: !type || type === 'solo',
                        enterprisePremium: !type || type === 'enterprise',
                        agencyBasic: type === 'enterprise',
                        agencyDeluxe: type === 'enterprise'
                    }).map(
                        (price, idx) =>
                            !price.disabled && (
                                <div key={price.id} className="w-full">
                                    <PriceCard
                                        price={price}
                                        interval={interval}
                                        isInView={isInView}
                                        idx={idx}
                                        freeTrialActivated={freeTrialActivated}
                                        subscriptionLinks={subscriptionLinks}
                                        type={type}
                                    />
                                </div>
                            )
                    )}
                </div>
            </motion.div>
        </section>
    );
}
