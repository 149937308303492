import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    data: [],
    error: null,
    searchQuery: '',
    techStackItems: [],
    industriesServed: []
};

export const devAgenciesSlice = createSlice({
    name: 'devAgencies',
    initialState,
    reducers: {
        fetchDevAgenciesRequest: state => {
            state.loading = true;
        },
        fetchDevAgenciesSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        },
        fetchDevAgenciesFailure: (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setTechStackItems: (state, action) => {
            state.techStackItems = action.payload;
        },
        setIndustriesServed: (state, action) => {
            state.industriesServed = action.payload;
        },
        clearDevAgencies: state => {
            state.loading = false;
            state.data = [];
            state.error = null;
            state.searchQuery = '';
            state.techStackItems = [];
            state.industriesServed = [];
        }
    }
});

export const {
    fetchDevAgenciesRequest,
    fetchDevAgenciesSuccess,
    fetchDevAgenciesFailure,
    setSearchQuery,
    setTechStackItems,
    setIndustriesServed,
    clearDevAgencies
} = devAgenciesSlice.actions;

export const selectDevAgencies = state => state.devAgencies.data;
export const selectDevAgenciesLoading = state => state.devAgencies.loading;
export const selectDevAgenciesError = state => state.devAgencies.error;
export const selectSearchQuery = state => state.devAgencies.searchQuery;
export const selectTechStackItems = state => state.devAgencies.techStackItems;
export const selectIndustriesServed = state =>
    state.devAgencies.industriesServed;

export default devAgenciesSlice.reducer;
