import { useDispatch } from 'react-redux';
import {
    DocumentIcon,
    PaperClipIcon,
    PlusIcon,
    PhotoIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'sonner';

import { getSignedUploadURL } from '../../../../../redux/actions/solo/getSignedUploadURL';
import { getSignedDownloadURL } from '../../../../../redux/actions/solo/getSignedDownloadURL';
import { useSubscriptionPayment } from '../../../../../context-providers/SubscriptionPayment';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import trackEvent from '../../../../../helpers/trackEvent';

export const AttachFile = ({
    isShare,
    setImageUrl,
    setFileKeyS3,
    setFile,
    setProgress,
    progress,
    setUploading,
    isUploading,
    file,
    from
}) => {
    const dispatch = useDispatch();
    const { isShare: subscriptionShare } = useSubscriptionPayment();
    const { accessTokenPayload } = useSessionContext();

    const cancelFileUpload = e => {
        if (handleKeyDown(e)) {
            return;
        }

        if (e.key === 'Enter') return;
        setFile(null);
        setFileKeyS3(null);
        setImageUrl(null);
        setProgress(0);
    };

    const handleFileChange = async e => {
        try {
            let file = e.target.files[0];

            if (
                file.type.startsWith('image/') &&
                file.size > 20 * 1024 * 1024
            ) {
                toast.error('Image size should be less than 15MB');
                setUploading(false);
                return;
            }
            const resizeImage = async file => {
                return new Promise((resolve, reject) => {
                    const img = document.createElement('img');
                    const canvas = document.createElement('canvas');
                    const reader = new FileReader();
                    reader.onload = function (e) {
                        img.src = e.target.result;
                        img.onload = function () {
                            let width = img.width;
                            let height = img.height;
                            const maxLongSide = 2000;
                            const maxShortSide = 768;

                            if (width > height) {
                                if (width > maxLongSide) {
                                    height *= maxLongSide / width;
                                    width = maxLongSide;
                                }
                                if (height > maxShortSide) {
                                    width *= maxShortSide / height;
                                    height = maxShortSide;
                                }
                            } else {
                                if (height > maxLongSide) {
                                    width *= maxLongSide / height;
                                    height = maxLongSide;
                                }
                                if (width > maxShortSide) {
                                    height *= maxShortSide / width;
                                    width = maxShortSide;
                                }
                            }

                            canvas.width = width;
                            canvas.height = height;
                            const ctx = canvas.getContext('2d');
                            ctx.drawImage(img, 0, 0, width, height);
                            canvas.toBlob(blob => {
                                if (blob) {
                                    resolve(
                                        new File([blob], file.name, {
                                            type: file.type
                                        })
                                    );
                                } else {
                                    reject(new Error('Image resizing failed.'));
                                }
                            }, file.type);
                        };
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            };

            if (file.type.startsWith('image/')) {
                file = await resizeImage(file);
            }

            setFile(file);
            if (!file) return;
            setUploading(true);

            // Randomize filename
            let uniqueFilename = uuidv4() + file.name;

            if (file.size > 50 * 1024 * 1024) {
                toast.error('File size should be less than 50MB');
                setUploading(false);
                return;
            }

            // 1. Fetch the signed URL
            const signedUrl = await dispatch(
                getSignedUploadURL(uniqueFilename)
            );

            // 2. Upload the file using the signed URL
            await new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('PUT', signedUrl, true);
                xhr.upload.onprogress = function (event) {
                    if (event.lengthComputable) {
                        const percentComplete =
                            (event.loaded / event.total) * 100;
                        setProgress(percentComplete.toFixed(2));
                        console.log(
                            `File upload progress: ${percentComplete.toFixed(
                                2
                            )}%`
                        );
                    }
                };
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        console.log('File uploaded successfully.');
                        resolve();
                    } else {
                        console.error('File upload failed.');
                        reject(new Error('File upload failed.'));
                    }
                };
                xhr.onerror = function () {
                    console.error('File upload failed.');
                    reject(new Error('File upload failed.'));
                };
                xhr.send(file);
            });

            if (file.type.startsWith('image/')) {
                const url = await dispatch(
                    getSignedDownloadURL(uniqueFilename)
                );
                setImageUrl(url);
            }

            setFileKeyS3(uniqueFilename);
            setUploading(false);
        } catch (err) {
            console.log(err);
            toast.error('Error uploading file');
            setUploading(false);
        }
    };

    const uploadClicked = e => {
        if (handleKeyDown(e)) {
            return;
        }
        if (isShare || subscriptionShare) {
            toast.error('Please login to upload files');
            return;
        }
        if (from === 'landing') {
            trackEvent('landingFileAttachment', {});
        } else {
            trackEvent('fileAttachment', {
                userEmail: accessTokenPayload?.email
            });
        }
        e.preventDefault();
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*,.pdf,.docx,.pptx,.xlsx,.odt,.odp,.ods,.txt';
        fileInput.style.display = 'none'; // Hide the file input element
        document.body.appendChild(fileInput); // Append to the body to ensure it works on mobile
        fileInput.onchange = handleFileChange;
        fileInput.click();
        fileInput.remove(); // Remove the input from the DOM after the file has been selected
    };

    const handleKeyDown = e => {
        if (e.clientX === 0 && e.clientY === 0) return true;
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            return true;
        }
        return false;
    };

    return (
        <>
            {!isUploading && !file && (
                <button
                    onClick={uploadClicked}
                    className="p-1.5 rounded-full border border-gray-600 hover:border-[var(--widget-primary-color,#4F46E5)] hover:bg-[var(--widget-primary-color,#4F46E5)]/10 transition-colors duration-200"
                >
                    <PlusIcon className="w-4 h-4 text-gray-900 hover:text-[var(--widget-primary-color,#4F46E5)]" />
                </button>
            )}

            {file && !isUploading && (
                <div className="flex flex-row gap-x-2 items-center rounded-lg p-2 hover:bg-[var(--widget-primary-color,#4F46E5)]/10 bg-[var(--widget-primary-color,#4F46E5)]/5">
                    {file && file.type.startsWith('image/') ? (
                        <PhotoIcon className="w-4 h-4" />
                    ) : (
                        <DocumentIcon className="w-4 h-4" />
                    )}

                    <span className="text-xs text-[var(--widget-text-color,#111827)] flex">
                        {file &&
                            file.name.substring(0, 10) +
                                (file.name.length > 10 ? '...' : '')}
                    </span>

                    <button
                        onClick={cancelFileUpload}
                        className="text-[var(--widget-text-color,#111827)] hover:text-[var(--widget-primary-color,#4F46E5)]"
                    >
                        <XMarkIcon className="w-4 h-4" />
                    </button>
                </div>
            )}

            {isUploading && (
                <div className="flex flex-col gap-y-1 w-[150px] px-2">
                    <div className="w-full bg-gray-200 rounded-lg h-8 relative">
                        <div
                            className="bg-[var(--widget-primary-color,#4F46E5)]/50 h-8 rounded-lg"
                            style={{ width: `${progress}%` }}
                        >
                            <span className="w-full text-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-black text-xs">
                                {progress}% -{' '}
                                {file &&
                                    file.name.substring(0, 5) +
                                        (file.name.length > 5 ? '...' : '')}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
