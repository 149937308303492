'use client';

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger
} from '../Components/magicui/accordion';

const faqs = [
    {
        question: 'Who is pre.dev designed for?',
        answer: (
            <div>
                <p className="text-gray-700 dark:text-gray-300 mb-4">
                    pre.dev is designed for anyone who needs to build software
                    products fast - from solo founders to enterprise teams. Our
                    instant prototype and plan generation accelerates product
                    development at any scale:
                </p>
                <ul className="list-none space-y-2 mb-4">
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Startup founders</strong> who need to
                            quickly validate ideas, generate MVPs, and attract
                            investment with working prototypes in days instead
                            of months.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Product managers</strong> who can now
                            generate complete product plans, architecture
                            diagrams, and roadmaps in minutes versus weeks of
                            planning meetings.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Enterprise innovation teams</strong> who can
                            rapidly prototype new features or products without
                            tying up engineering resources for months.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Development agencies</strong> who can slash
                            estimation and discovery phases while delivering
                            more accurate client proposals with functional
                            prototypes.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Non-technical founders</strong> who can turn
                            their ideas into detailed technical specifications
                            and working prototypes without needing to code.
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Technical executives</strong> who need to
                            rapidly evaluate technical approaches and make
                            informed architecture decisions with real
                            prototypes.
                        </span>
                    </li>
                </ul>
                <p className="text-gray-700 dark:text-gray-300">
                    Whether you're a solo entrepreneur with a new app idea or an
                    enterprise team launching your next product line, pre.dev
                    transforms months of planning and development into hours or
                    days, enabling you to build and iterate on functional
                    software prototypes at unprecedented speed.
                </p>
            </div>
        )
    },
    {
        question: 'What does pre.dev actually do?',
        answer: (
            <div className="space-y-4">
                <p className="text-gray-700 dark:text-gray-300">
                    pre.dev is an AI Product Architect that helps you go from
                    idea to production-ready code. Our platform:
                </p>
                <ul className="list-none space-y-2">
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Instantly generates prototypes</strong>{' '}
                            based on your idea description
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Designs software architecture</strong> based
                            on your project requirements
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Recommends optimal tech stacks</strong> for
                            your specific project needs
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>
                                Generates comprehensive documentation
                            </strong>{' '}
                            like Statement of Work (SOW)
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Creates detailed task roadmaps</strong> and
                            can integrate with Jira/Linear
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Exports Master Prompts</strong> for use with
                            AI coding assistants like Cursor, Claude Code, and
                            others
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Supports continuous improvement</strong>{' '}
                            with iteration-based development
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>
                                Connects you with pre-vetted developers
                            </strong>{' '}
                            who can build your product
                        </span>
                    </li>
                </ul>
                <p className="text-gray-700 dark:text-gray-300">
                    Our platform reduces product development time and costs by
                    providing instant prototypes and a comprehensive roadmap
                    before any significant development investment.
                </p>
            </div>
        )
    },
    {
        question: 'How fast can I get a prototype?',
        answer: (
            <div className="space-y-4">
                <p className="text-gray-700 dark:text-gray-300">
                    With pre.dev, you can go from idea to functional prototype
                    in minutes, not weeks or months. Our AI-powered platform:
                </p>
                <ul className="list-none space-y-2">
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Instantly analyzes</strong> your product
                            idea description
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Generates comprehensive plans</strong>{' '}
                            including architecture diagrams and UI mockups
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Produces functional code</strong> through
                            our Master Prompt system
                        </span>
                    </li>
                </ul>
                <p className="text-gray-700 dark:text-gray-300">
                    You can iterate on your prototype immediately, making
                    adjustments and seeing results in real-time. This rapid
                    prototyping approach allows you to validate ideas quickly
                    and make informed decisions about which features to
                    prioritize before committing significant development
                    resources.
                </p>
            </div>
        )
    },
    {
        question: 'What is the Master Prompt feature?',
        answer: (
            <div className="space-y-4">
                <p className="text-gray-700 dark:text-gray-300">
                    The Master Prompt is a powerful new feature that allows you
                    to export your entire project plan in a format that any AI
                    coding assistant can use to generate the complete codebase.
                    It includes:
                </p>
                <ul className="list-none space-y-2">
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Cursor Rules:</strong> Implementation
                            guidelines and coding standards
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Predev Plan:</strong> Project architecture
                            and development roadmap
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>README:</strong> Usage instructions and
                            documentation
                        </span>
                    </li>
                </ul>
                <p className="text-gray-700 dark:text-gray-300">
                    The Master Prompt works with all major AI coding assistants
                    including Cursor, Claude Code, Cline, Codeium, and GitHub
                    Copilot. Simply download, unzip, and instruct your AI agent
                    to "Execute the plan to generate the full project based on
                    these documents."
                </p>
            </div>
        )
    },
    {
        question: 'How does this differentiate vs Chat GPT?',
        answer: "pre.dev is custom built for software product planning and development. Unlike general-purpose AI tools, pre.dev is specifically fine-tuned with unique datasets and orchestration techniques for product development. It offers specialized features like instant prototype generation, architecture visualization, tech stack recommendations, roadmap generation, Jira/Linear integration, UI/UX code generation, and the ability to export Master Prompts for any AI coding assistant—capabilities that ChatGPT, Claude, and other general LLMs don't offer. Most importantly, pre.dev enables you to iterate and continuously improve your prototype in minutes, significantly accelerating your product development cycle."
    },
    {
        question: 'We already have PMs, why should I use this?',
        answer: "pre.dev augments and enhances PM productivity rather than replacing them. It helps PMs generate instant prototypes, architecture, documentation, and plans faster, allowing them to focus on strategy and execution. PMs can rapidly iterate on product concepts, get stakeholder feedback on working prototypes, and refine designs in minutes rather than weeks. Since it's trained on historical task completion data, pre.dev can often more accurately estimate task completion timeframes compared to human intuition. PMs who use pre.dev can manage more projects simultaneously while maintaining quality and consistency."
    },
    {
        question: 'How does the developer matching work?',
        answer: 'After pre.dev helps you create your product architecture and planning documents, we can optionally connect you with pre-vetted, professional developers who have the exact skills needed for your project. Our system analyzes your project requirements, tech stack, and budget to find the perfect match. This service helps you avoid the time-consuming process of finding, vetting, and onboarding developers on your own, while ensuring you get quality development services at competitive rates. The developers can hit the ground running with your pre.dev generated prototype, significantly reducing time-to-market for your product.'
    },
    {
        question: 'How does continuous improvement work with pre.dev?',
        answer: (
            <div className="space-y-4">
                <p className="text-gray-700 dark:text-gray-300">
                    pre.dev's continuous improvement model allows you to rapidly
                    iterate on your product:
                </p>
                <ul className="list-none space-y-2">
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Start with an instant prototype</strong>{' '}
                            generated from your initial idea
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Gather feedback</strong> from stakeholders
                            on the prototype
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Refine your requirements</strong> based on
                            insights and feedback
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Generate new iterations</strong> of your
                            prototype in minutes
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            ></path>
                        </svg>
                        <span className="text-gray-700 dark:text-gray-300">
                            <strong>Export Master Prompts</strong> for each
                            iteration to see functional code
                        </span>
                    </li>
                </ul>
                <p className="text-gray-700 dark:text-gray-300">
                    This rapid, iterative approach compresses what traditionally
                    takes months into days or even hours. You can validate
                    product-market fit early, experiment with different features
                    and designs, and arrive at a finalized product specification
                    much faster than traditional development methods.
                </p>
            </div>
        )
    },
    {
        question: 'Why should I stay subscribed?',
        answer: 'Product development is a continuous cycle of improvements and new features. The longer you use pre.dev, the more valuable it becomes as it learns about your product, processes, and preferences. A subscription provides ongoing access to all features, regular updates with new capabilities, and consistent time and cost savings throughout your product development lifecycle. You can continuously generate new prototypes for feature additions, product variations, or entirely new products, all while leveraging your existing knowledge base within pre.dev. For teams, it also ensures alignment and standardization across multiple projects, creating a consistent development approach.'
    },
    {
        question: 'Is my data safe?',
        answer: "All data is private and encrypted at rest on solo and enterprise plans. pre.dev takes data security seriously and does not train on any enterprise clients' data. Your project information, documents, and conversations remain confidential and are only accessible to you and team members you explicitly grant access to."
    },
    {
        question: 'Can this be used by teams? Do you have an enterprise plan?',
        answer: "pre.dev is excellent for teams, and we offer enterprise plans tailored to your organization's specific needs starting at $99 per seat. Enterprise plans include additional features like team collaboration tools, admin controls, SSO integration, and priority support. Please contact us at arjun@pre.dev for more information or if you need more than 5 seats."
    }
];

export function FAQ() {
    return (
        <section id="faq">
            <div className="py-14">
                <div className="container mx-auto px-4 md:px-8">
                    <div className="mx-auto max-w-5xl text-center">
                        <h4 className="text-xl font-bold tracking-tight text-black dark:text-white">
                            FAQs
                        </h4>
                        <h2 className="text-4xl font-bold tracking-tight text-black dark:text-white sm:text-6xl">
                            Frequently Asked Questions
                        </h2>
                        <p className="mt-6 text-xl leading-8 text-black/80 dark:text-white">
                            Need help with something? Here are some of the most
                            common questions we get.
                        </p>
                    </div>
                    <div className="container mx-auto my-12 max-w-5xl">
                        <Accordion
                            type="single"
                            collapsible
                            className="flex w-full flex-col items-center justify-center"
                            defaultValue={faqs[0].question}
                        >
                            {faqs.map((faq, idx) => (
                                <AccordionItem
                                    key={idx}
                                    value={faq.question}
                                    className="w-full px-4"
                                >
                                    <AccordionTrigger className="text-black dark:text-white">
                                        {faq.question}
                                    </AccordionTrigger>
                                    <AccordionContent className="text-black dark:text-gray-300">
                                        {faq.answer}
                                    </AccordionContent>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                    <h4 className="mb-12 text-center text-sm font-medium tracking-tight text-foreground/80">
                        Still have questions? Email us at{' '}
                        <a href="mailto:arjun@pre.dev" className="underline">
                            arjun@pre.dev
                        </a>
                    </h4>
                </div>
            </div>
        </section>
    );
}

export default FAQ;
