import { client } from '../../../api/client';
import { GET_ARCHITECTURE_PROGRESS } from '../../../api/graphQL/solo/query';

export const getArchitectureProgress = projectId => async dispatch => {
    try {
        const { data } = await client.query({
            query: GET_ARCHITECTURE_PROGRESS,
            variables: { projectId },
            fetchPolicy: 'network-only'
        });

        return data.getArchitectureProgress;
    } catch (error) {
        console.error('Error fetching architecture progress:', error);
        return null;
    }
};
