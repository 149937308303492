import { appendChat } from '../../reducers/generic/chatHistory';

// Initializes the chathistory directly and then disables text input
export const addToChatHistory = chat => async (dispatch, getState) => {
    let chatHistory = getState().chatHistory.data;
    if (chat && chat.role !== chatHistory[chatHistory.length - 1]?.role) {
        console.log('added ' + chat.role);
        dispatch(appendChat(chat));
    } else {
        console.log('not added ' + chat?.role);
        console.log(chatHistory[chatHistory.length - 1]?.content);
        console.log(chat?.content);
        if (chatHistory[chatHistory.length - 1]?.content === chat?.content) {
            dispatch(appendChat(chat));
        }
    }
};
