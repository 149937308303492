import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userAuthModalOpen: false,
    masterPromptModalOpen: false
};

const landingSlice = createSlice({
    name: 'landing',
    initialState,
    reducers: {
        setUserAuthModalOpen: (state, action) => {
            state.userAuthModalOpen = action.payload;
        },
        setMasterPromptModalOpen: (state, action) => {
            state.masterPromptModalOpen = action.payload;
        }
    }
});

export const { setUserAuthModalOpen, setMasterPromptModalOpen } =
    landingSlice.actions;

export const selectUserAuthModalOpen = state => state.landing.userAuthModalOpen;
export const selectMasterPromptModalOpen = state =>
    state.landing.masterPromptModalOpen;

export default landingSlice.reducer;
