import { useState, useEffect, useRef } from 'react';
import { submitHeroExperience } from '../../../redux/actions/solo/submitHeroExperience';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { getProjectByLink } from '../../../redux/actions/solo/getProjectByLink';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { PlaceholdersAndVanishInput } from '../../Components/magicui/cool-input';
import { placeholders } from './random_ideas';
import { updateProjectWithEmail } from '../../../redux/actions/solo/updateProjectWithEmail';
import { setRedirectURL } from '../../../constants';
import trackEvent from '../../../helpers/trackEvent';
import { motion } from 'framer-motion';

export const InputArea = ({
    setAnswers,
    setShowInput,
    setCountdown,
    setLoading,
    setQuestionIndex,
    questionIndex,
    matchWithDev,
    urgency,
    tempProjectId,
    setTempProjectId
}) => {
    const navigate = useNavigate();
    const [answer, setAnswer] = useState('');
    const [fileKeyS3, setFileKeyS3] = useState(null);
    const [file, setFile] = useState(null);
    const [budget, setBudget] = useState(10000);
    const { accessTokenPayload } = useSessionContext();
    const userEmail = accessTokenPayload?.email;
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (userEmail && questionIndex === 3) setAnswer(userEmail);
    }, [questionIndex, userEmail]);

    const processFirstAnswer = async answer => {
        setAnswers(prevAnswers => [...prevAnswers, answer]);
        const project_id = await dispatch(
            submitHeroExperience({
                answers: [answer],
                userEmail: null,
                matchWithDev,
                budget,
                urgency,
                fileKeyS3
            })
        );
        trackEvent('firstHeroQuestionAnswered', {
            userEmail,
            projectId: project_id
        });
        setSearchParams({ redirectToPath: `/projects/${project_id}` });
        setRedirectURL(`/projects/${project_id}`);
        setTempProjectId(project_id);
    };
    const processEmail = async answer => {
        const email = answer.trim();
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            toast('Please enter a valid email address.');
            return;
        }
        setLoading(true);
        let countdownTime = 5;
        setCountdown(countdownTime);

        let ad_params = localStorage.getItem('ad_params');
        if (ad_params) {
            ad_params = JSON.parse(ad_params);
        }
        console.log(ad_params);
        dispatch(
            updateProjectWithEmail(
                tempProjectId,
                email,
                ad_params?.utm_source,
                ad_params?.utm_medium,
                ad_params?.utm_campaign,
                ad_params?.utm_url
            )
        );

        try {
            let countInterval = setInterval(() => {
                if (countdownTime > 0) {
                    countdownTime--;
                    setCountdown(countdownTime);
                } else if (tempProjectId) {
                    navigate('/demo/' + tempProjectId);
                    clearInterval(countInterval);
                }
            }, 1000);
            trackEvent('emailHeroQuestionAnswered', {
                userEmail,
                projectId: tempProjectId
            });
            dispatch(getProjectByLink(tempProjectId));
        } catch (e) {
            navigate('/auth');
        }
    };

    const processAnswer = async () => {
        if (questionIndex === 0 && answer.length < 1 && !fileKeyS3) {
            toast('Please enter an answer.');
            return;
        }
        setShowInput(false);
        setQuestionIndex(questionIndex + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        if (questionIndex === 1) {
            processEmail(answer);
        } else if (questionIndex === 0) {
            processFirstAnswer(answer);
        }
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Initial check
        checkIfMobile();

        // Add event listener
        window.addEventListener('resize', checkIfMobile);

        // Cleanup
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    const placeholderText =
        questionIndex === 0 ? 'I would like to build a...' : 'steve@apple.com';

    let textAreaHeight = 'h-[200px]';
    if (questionIndex === 2) textAreaHeight = 'h-[80px]';

    return (
        <MainInput
            answer={answer}
            setAnswer={setAnswer}
            fileKeyS3={fileKeyS3}
            setFileKeyS3={setFileKeyS3}
            questionIndex={questionIndex}
            placeholderText={placeholderText}
            textAreaHeight={textAreaHeight}
            processAnswer={processAnswer}
            file={file}
            setFile={setFile}
        />
    );
};
const MainInput = ({
    answer,
    setAnswer,
    fileKeyS3,
    setFileKeyS3,
    questionIndex,
    placeholderText,
    processAnswer,
    textAreaHeight,
    file,
    setFile
}) => {
    const textareaRef = useRef(null);
    const containerRef = useRef(null);

    const handleSubmit = async () => {
        setTimeout(async () => {
            if (containerRef.current) {
                containerRef.current.style.transition = 'opacity 1s';
                containerRef.current.style.opacity = '0';
                await new Promise(resolve => setTimeout(resolve, 500));
                processAnswer();
            }
        }, 200);
    };

    return (
        <div className="mx-auto relative">
            <div
                ref={containerRef}
                className="flex items-center relative mx-auto max-w-xl dark"
            >
                <PlaceholdersAndVanishInput
                    placeholders={
                        questionIndex === 1 ? ['steve@apple.com'] : placeholders
                    }
                    showAttachFile={questionIndex === 0}
                    ref={textareaRef}
                    id="mainInput"
                    questionIndex={questionIndex}
                    type={questionIndex === 1 ? 'email' : 'text'}
                    onChange={e => setAnswer(e.target.value)}
                    onSubmit={handleSubmit}
                    fileKeyS3={fileKeyS3}
                    setFileKeyS3={setFileKeyS3}
                    file={file}
                    setFile={setFile}
                />
            </div>

            {questionIndex === 0 && (
                <motion.div
                    className="mt-8 sm:mt-8 text-center relative max-w-sm mx-auto z-10 flex flex-col items-center justify-center"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        delay: 0.5,
                        duration: 0.5,
                        type: 'spring',
                        stiffness: 100
                    }}
                >
                    <motion.div
                        className="absolute -top-4 text-blue-500"
                        animate={{
                            y: [0, -8, 0],
                            scale: [1, 1.1, 1],
                            opacity: [0.7, 1, 0.7]
                        }}
                        transition={{
                            duration: 1.8,
                            repeat: Infinity,
                            repeatType: 'loop'
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="drop-shadow-[0_0_10px_rgba(59,130,246,0.7)]"
                        >
                            <path d="m18 15-6-6-6 6" />
                        </svg>
                    </motion.div>
                    <span className="bg-gradient-to-r from-sky-400 to-blue-400 bg-clip-text text-transparent font-bold text-lg sm:text-xl px-4 py-1 relative inline-block">
                        <motion.span
                            className="absolute inset-0 bg-gradient-to-r from-sky-400 to-blue-400 rounded-full blur-xl opacity-30"
                            animate={{ opacity: [0.2, 0.4, 0.2] }}
                            transition={{
                                duration: 2,
                                repeat: Infinity,
                                repeatType: 'loop'
                            }}
                        />
                        Your Idea Here*
                    </span>
                    <a
                        href="https://app.termly.io/policy-viewer/policy.html?policyUUID=001064ff-9086-48f5-aebe-68ee93343415"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xs text-gray-500 hover:text-blue-300 transition-colors"
                    >
                        All projects are secured by GCP and kept private per
                        account.
                    </a>
                </motion.div>
            )}
        </div>
    );
};
