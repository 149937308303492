import React, { createContext, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    fetchProjectRequest,
    selectProject,
    clearStateProject,
    setProjectId,
    selectIsLeadGen,
    setArchitectureProgress
} from '../redux/reducers/generic/project';
import {
    selectChatHistory,
    clearStateChatHistory
} from '../redux/reducers/generic/chatHistory';
import { clearStateChatHistory as clearStateChatHistoryUI } from '../redux/reducers/generic/chatHistory';
import {
    selectProjectArchitecture,
    clearStateProjectArchitecture,
    setUIView
} from '../redux/reducers/generic/projectArchitecture';
import { getProjectByUser } from '../redux/actions/solo/getProjectByUser';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getProjectByEnterpriseOrganization } from '../redux/actions/enterprise/getProjectByEnterpriseOrganization';
import { getProjectByLink } from '../redux/actions/solo/getProjectByLink';
import { getProjectByEnterpriseWidget } from '../redux/actions/enterprise_widget/getProjectByEnterpriseWidget';
import {
    selectAPIKey,
    selectWidgetState
} from '../redux/reducers/enterprise_widget/enterpriseWidget';
import {
    setSow,
    setPrices,
    setPrice
} from '../redux/reducers/generic/projectArchitecture';
import { setJira } from '../redux/reducers/generic/jira';

import { getEnterpriseOrganizationByApiKey } from '../redux/actions/enterprise_widget/getEnterpriseOrganizationByApiKey';
import { useSearchParams } from 'react-router-dom';
import { selectSkipClicked } from '../redux/reducers/generic/projects';
import { useState } from 'react';
import { useSubscriptionPayment } from './SubscriptionPayment';
import { getProjectByLeadGen } from '../redux/actions/enterprise/getProjectByLeadGen';
import { getNumUIGenerationsLeft } from '../redux/actions/solo/get_num_ui_generations_left';
import { getArchitectureProgress } from '../redux/actions/solo/getArchitectureProgress';

const ProjectContext = createContext();

export const ProjectProvider = ({ children, isChatWidget }) => {
    const { isSolo, isShare, isEnterprise, isHomePage } =
        useSubscriptionPayment();
    const dispatch = useDispatch();
    let project = useSelector(selectProject);
    let chatHistory = useSelector(selectChatHistory);
    let widgetState = useSelector(selectWidgetState);
    let projectArchitecture = useSelector(selectProjectArchitecture);
    let skipClicked = useSelector(selectSkipClicked);
    const apiKey = useSelector(selectAPIKey);
    const [searchParams, setParams] = useSearchParams();
    const pageType = searchParams.get('pageType');
    const graphType = searchParams.get('graphType');
    let allSearchParams = Object.fromEntries(searchParams);
    const isLeadGen = useSelector(selectIsLeadGen);

    let { projectId, enterpriseOrganizationId } = useParams();

    const setSearchParams = params => {
        console.log('setting search params', params);
        setParams(params);

        // Verify that params were actually set
        setTimeout(() => {
            const currentParams = Object.fromEntries(
                new URLSearchParams(window.location.search)
            );
            console.log('params after setting:', currentParams);
            if (JSON.stringify(currentParams) !== JSON.stringify(params)) {
                console.warn('Search params may not have been set correctly', {
                    attempted: params,
                    actual: currentParams
                });
                // Try setting again if needed
                setParams(params);
            }
        }, 10);
    };

    const [prevSkipClicked, setPrevSkipClicked] = useState(skipClicked);

    useEffect(() => {
        if (prevSkipClicked !== skipClicked) {
            setTimeout(() => {
                setPrevSkipClicked(skipClicked);
            }, 100);
        }
    }, [skipClicked]);

    let clearState = () => {
        dispatch(fetchProjectRequest());
        dispatch(clearStateProject());
        dispatch(clearStateChatHistory());
        dispatch(clearStateChatHistoryUI());
        dispatch(clearStateProjectArchitecture());
        dispatch(setSow(null));
        dispatch(setPrices(null));
        dispatch(setPrice(null));
        dispatch(setJira(null));
        dispatch(setUIView(null));
    };

    useEffect(() => {
        if (!projectId && !enterpriseOrganizationId) {
            clearState();
        }
    }, [projectId, enterpriseOrganizationId]);

    useEffect(() => {
        if (projectId) {
            dispatch(getNumUIGenerationsLeft());
        }
    }, [projectId]);

    //in Widget|Full Widget Interface
    useEffect(() => {
        if (apiKey && !enterpriseOrganizationId)
            dispatch(getEnterpriseOrganizationByApiKey(apiKey));
    }, [apiKey, enterpriseOrganizationId]);

    //in Widget Interface
    useEffect(() => {
        if (isChatWidget && projectId && apiKey) {
            clearState();
            dispatch(getProjectByEnterpriseWidget({ apiKey, projectId }));
            dispatch(setProjectId(projectId));
        }
    }, [projectId, isChatWidget, apiKey]);

    //in Solo Interface
    useEffect(() => {
        if (isSolo && projectId) {
            if (prevSkipClicked === skipClicked) clearState();
            dispatch(getProjectByUser(projectId));
            dispatch(setProjectId(projectId));
        }
    }, [projectId, isSolo, skipClicked]);

    //in Share Interface
    useEffect(() => {
        if ((isShare || isHomePage) && projectId) {
            if (projectId != project?._id) clearState();
            dispatch(getProjectByLink(projectId));
            dispatch(setProjectId(projectId));
        }
    }, [projectId, isShare, isHomePage]);

    const getProgress = async () => {
        const progress = await dispatch(getArchitectureProgress(projectId));
        if (progress) {
            dispatch(setArchitectureProgress(progress.items));
        }
    };

    useEffect(() => {
        if (projectId) {
            getProgress();
        }
    }, [projectId]);
    //in Enterprise Interface
    useEffect(() => {
        if (isEnterprise && enterpriseOrganizationId && projectId) {
            if (prevSkipClicked === skipClicked) clearState();

            if (isLeadGen) {
                dispatch(
                    getProjectByLeadGen({
                        projectId,
                        enterpriseOrganizationId
                    })
                );
            } else {
                dispatch(
                    getProjectByEnterpriseOrganization({
                        projectId,
                        enterpriseOrganizationId
                    })
                );
            }
            dispatch(setProjectId(projectId));
        }
    }, [
        projectId,
        enterpriseOrganizationId,
        isEnterprise,
        skipClicked,
        isLeadGen
    ]);

    return (
        <ProjectContext.Provider
            value={{
                setSearchParams,
                allSearchParams,
                project,
                chatHistory,
                widgetState,
                projectArchitecture,
                projectId: projectId || project?._id,
                pageType,
                graphType,
                enterpriseOrganizationId
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};

export const useProject = () => useContext(ProjectContext);
