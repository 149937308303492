import { ChatInput } from '../../Chat/ChatInput';
import { ExistingProjectContext } from '../../Chat/ExistingProjectContext';
import { Progress } from '../../Chat/Progress';
import ChatHistory from '../../Chat/ChatHistory';

const ChatWidget = () => {
    return (
        <>
            <div className="h-[65px] flex flex-col justify-end border-b border-gray-200">
                <Progress />
            </div>
            <ExistingProjectContext />
            <ChatHistory />
            <ChatInput isWidget={true} />
        </>
    );
};
export default ChatWidget;
