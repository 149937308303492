import levenshtein from 'fast-levenshtein';

export const assignStoriesToUserFlowGraph = (userFlowGraph, milestones) => {
    try {
        let updatedGraph = JSON.parse(JSON.stringify(userFlowGraph));
        updatedGraph.nodes = updatedGraph.nodes.map(node => {
            if (node.data && node.data.stories) {
                node.data.stories = [];
            }
            return node;
        });

        for (const milestone of milestones) {
            for (const story of milestone.stories) {
                try {
                    const nodeIndex = updatedGraph.nodes.findIndex(
                        node => node.data._id === story.nodeId
                    );
                    const node = updatedGraph.nodes[nodeIndex];

                    if (nodeIndex !== -1) {
                        if (!updatedGraph.nodes[nodeIndex].data.stories) {
                            updatedGraph.nodes[nodeIndex].data.stories = [];
                        }
                        updatedGraph.nodes[nodeIndex].data.stories.push(story);
                    }
                } catch (error) {
                    console.log('error in assignStoriesToUserFlowGraph');
                    console.log({ error });
                }
            }
        }

        return updatedGraph;
    } catch (error) {
        return userFlowGraph;
    }
};

export const assignStoriesToBackendGraph = (backendGraph, milestones) => {
    backendGraph.nodes = backendGraph.nodes.map(selectedNode => {
        let stories = milestones
            ?.map(milestone => milestone.stories)
            .flat()
            .filter(
                story =>
                    story.architectureNodeIdCitations?.includes(
                        selectedNode?.id
                    )
            );

        selectedNode.data.stories = stories;
        return selectedNode;
    });

    return backendGraph;
};

export const assignStoriesToFrontendGraph = (
    frontendGraph,
    userFlowGraph,
    milestones
) => {
    const userFlowNodes = userFlowGraph?.nodes;

    frontendGraph.nodes = frontendGraph.nodes.map(selectedNode => {
        const reactPageNodes = userFlowNodes?.filter(
            node =>
                node.reactPage &&
                levenshtein.get(
                    node?.reactPage?.replace(/\s+/g, '').toLowerCase(),
                    selectedNode?.data?.label?.replace(/\s+/g, '').toLowerCase()
                ) === 0
        );

        const stories = milestones
            ?.map(milestone => milestone.stories)
            .flat()
            .filter(
                story => reactPageNodes?.some(node => node._id === story.nodeId)
            );

        selectedNode.data.stories = stories;
        return selectedNode;
    });

    return frontendGraph;
};
