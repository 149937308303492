import {
    filterGraphByBudget,
    filterUserFlowGraphByBudget,
    filterMilestonesByBudget
} from './filter';
import { labelNodesWithDepth } from './label_nodes_with_depth';
import { Graph } from './graphTypes';
import { updateHourEstimates } from './update_hours_estimates';
import {
    assignStoriesToUserFlowGraph,
    assignStoriesToBackendGraph,
    assignStoriesToFrontendGraph
} from './assign_stories_to_nodes';

export const mapTasksToArchitecture = (
    milestones: any[],
    skillLevel: string,
    maxBudget: number,
    budgetFilterActivated: boolean,
    graphs: Graph[]
) => {
    try {
        
        if (!milestones)
            return {
                milestones,
                graphs
            };

        graphs = JSON.parse(JSON.stringify(graphs));
        if (graphs.length < 3) {
            return {
                milestones,
                graphs
            };
        }

        graphs = graphs.map(removeEmptyNodesEdges);

        let userFlowGraph = graphs.find(g => g.side === 'user flow');
        let backendGraph = graphs.find(g => g.side === 'backend');
        let frontendGraph = graphs.find(g => g.side === 'frontend');
        let otherGraphs = graphs.filter(
            g =>
                g.side !== 'user flow' &&
                g.side !== 'backend' &&
                g.side !== 'frontend'
        );

        // Update hour estimates based on skill level
        milestones = updateHourEstimates(milestones, skillLevel);

        // Label nodes with depth
        userFlowGraph = labelNodesWithDepth(userFlowGraph);

        //Assign each story to each node
        userFlowGraph = assignStoriesToUserFlowGraph(userFlowGraph, milestones);
        backendGraph = assignStoriesToBackendGraph(backendGraph, milestones);
        frontendGraph = assignStoriesToFrontendGraph(
            frontendGraph,
            userFlowGraph,
            milestones
        );

        // // Filter graphs and milestones by budget
        // if (budgetFilterActivated) {
        //     const { newUserFlowGraph, subTaskAddedMap } =
        //         filterUserFlowGraphByBudget(maxBudget, userFlowGraph);
        //     userFlowGraph = newUserFlowGraph;
        //     milestones = filterMilestonesByBudget(milestones, subTaskAddedMap);
        //     backendGraph = filterGraphByBudget(backendGraph, subTaskAddedMap);
        //     frontendGraph = filterGraphByBudget(frontendGraph, subTaskAddedMap);
        // }

        graphs = [userFlowGraph, frontendGraph, backendGraph, ...otherGraphs];

        return {
            milestones,
            graphs
        };
    } catch (error) {
        console.log({ error });
        return {
            milestones,
            graphs
        };
    }
};

const removeEmptyNodesEdges = (graph: Graph) => {
    // // Create a hashmap of valid node IDs
    // const validNodeIds = new Set(graph.nodes.map(node => node.id));

    // // Filter edges that have both valid source and target nodes
    // graph.edges = graph.edges.filter(
    //     edge =>
    //         validNodeIds.has(edge.source) &&
    //         validNodeIds.has(edge.target) &&
    //         edge.source !== edge.target
    // );

    // // Create a set of nodes that have edges
    // const nodesWithEdges = new Set([
    //     ...graph.edges.map(edge => edge.source),
    //     ...graph.edges.map(edge => edge.target)
    // ]);

    // // Only keep nodes that have at least one edge
    // graph.nodes = graph.nodes.filter(node => nodesWithEdges.has(node.id));

    return graph;
};
