import React, { createContext, useState, useEffect, useContext } from 'react';
import { client } from '../api/client';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import {
    GET_USER_SUBSCRIPTION,
    GET_USER_SUBSCRIPTIONS,
    GET_ENTERPRISE_SUBSCRIPTIONS
} from '../api/graphQL';
import { useEnterpriseOrganization } from './EnterpriseOrganization';
import { selectIsLeadGen } from '../redux/reducers/generic/project';
import { useSelector } from 'react-redux';
import { useSubscription } from '@apollo/client';

const SubscriptionPaymentContext = createContext();

export function SubscriptionPaymentProvider({
    children,
    isChatWidget,
    isSolo,
    isShare,
    isHomePage,
    isEnterprise
}) {
    const { doesSessionExist, accessTokenPayload } = useSessionContext();
    const [soloSubscriptionPayment, setSoloSubscriptionPayment] = useState({
        loading: true
    });
    let { enterpriseOrganization, enterpriseOrganizationId } =
        useEnterpriseOrganization();
    const [isSubbed, setIsSubbed] = useState(false);
    const [status, setStatus] = useState(null);
    const [tier, setTier] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const isLeadGen = useSelector(selectIsLeadGen);
    const [lastSubEnterpriseId, setLastSubEnterpriseId] = useState(null);
    const [lastSubUserId, setLastSubUserId] = useState(null);

    const setSub = sub => {
        if (isHomePage || isShare) {
            return;
        }
        setSubscription(sub);
        setStatus(sub?.status);
        setTier(sub?.tier);
        setIsSubbed(sub?.status === 'active');
    };

    const fetchSubscriptions = async () => {
        if (isHomePage || isShare) {
            return;
        }
        setSubscriptions([]);

        try {
            if (isEnterprise && enterpriseOrganizationId) {
                const { data } = await client.query({
                    query: GET_ENTERPRISE_SUBSCRIPTIONS,
                    variables: { enterpriseOrganizationId },
                    fetchPolicy: 'network-only'
                });

                if (data?.getEnterpriseSubscriptions) {
                    setSubscriptions(data.getEnterpriseSubscriptions);

                    // Set the primary subscription (first active one)
                    // const primarySub = data.getEnterpriseSubscriptions.find(
                    //     sub => sub.status === 'active'
                    // );
                    // if (primarySub) {
                    //     console.log({ primarySub });
                    //     setSub(primarySub);
                    // }
                }
            } else if (isSolo && accessTokenPayload?.userId) {
                const { data } = await client.query({
                    query: GET_USER_SUBSCRIPTIONS,
                    fetchPolicy: 'network-only'
                });

                if (data?.getUserSubscriptions) {
                    setSubscriptions(data.getUserSubscriptions);

                    // Set the primary subscription (first active one)
                    // const primarySub = data.getUserSubscriptions.find(
                    //     sub => sub.status === 'active'
                    // );
                    // if (primarySub) {
                    //     setSub(primarySub);
                    // }
                }
            }
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
        }
    };

    // Effect for handling subscription changes based on context
    useEffect(() => {
        if (isShare) {
            setSub(null);
        } else if (isHomePage) {
            setSub(null);
        } else if (isSolo) {
            if (accessTokenPayload?.userId) {
                setSub(soloSubscriptionPayment);
            }
        } else if (isEnterprise || isChatWidget) {
            if (enterpriseOrganizationId) {
                setSub(enterpriseOrganization?.subscriptionStatus);
            }
        } else {
            setSub(null);
        }
    }, [isShare, isSolo, soloSubscriptionPayment, enterpriseOrganization]);

    // Effect for fetching subscriptions when context changes
    useEffect(() => {
        if (isHomePage || isShare) {
            return;
        }

        if (
            isEnterprise &&
            enterpriseOrganizationId &&
            enterpriseOrganizationId !== lastSubEnterpriseId
        ) {
            setLastSubEnterpriseId(enterpriseOrganizationId);
        } else if (
            isSolo &&
            accessTokenPayload?.userId &&
            accessTokenPayload.userId !== lastSubUserId
        ) {
            setLastSubUserId(accessTokenPayload.userId);
        }
    }, [
        isEnterprise,
        isSolo,
        enterpriseOrganizationId,
        accessTokenPayload?.userId,
        lastSubEnterpriseId,
        lastSubUserId
    ]);

    // Effect for fetching initial subscription data
    useEffect(() => {
        async function getSubscriptionPayment() {
            try {
                const { data } = await client.query({
                    query: GET_USER_SUBSCRIPTION,
                    fetchPolicy: 'network-only'
                });
                setSoloSubscriptionPayment(data.userSubscriptionStatus);
                if (isSolo) {
                    setSub(data.userSubscriptionStatus);
                }
            } catch (error) {
                console.log({ error });
                setSub(false);
                setSoloSubscriptionPayment({ loading: false });
            }
        }
        if (doesSessionExist) {
            getSubscriptionPayment();
        } else {
            setSub(false);
            setSoloSubscriptionPayment({ loading: false });
        }
    }, [doesSessionExist, accessTokenPayload?.sessionHandle, isSolo]);

    return (
        <SubscriptionPaymentContext.Provider
            value={{
                isSubbed,
                status,
                tier,
                subscription,
                subscriptions,
                fetchSubscriptions,
                isSolo,
                isShare,
                isEnterprise,
                isHomePage,
                isLeadGen
            }}
        >
            {children}
        </SubscriptionPaymentContext.Provider>
    );
}

export const useSubscriptionPayment = () =>
    useContext(SubscriptionPaymentContext);
