import { FeaturedProject } from './types';

// Sample data for featured projects
// Replace this with actual data from your backend
export const sampleFeaturedProjects: FeaturedProject[] = [
    {
        id: '1',
        name: 'E-Commerce Platform',
        description:
            'A modern e-commerce solution with advanced product filtering and integrated payment systems.',
        screenshot:
            'https://images.unsplash.com/photo-1593642702821-c8da6771f0c6?q=80&w=1000&auto=format&fit=crop',
        url: 'https://example.com/project1'
    },
    {
        id: '2',
        name: 'Health & Fitness App',
        description:
            'Mobile application for tracking workouts, nutrition, and overall wellness goals.',
        screenshot:
            'https://images.unsplash.com/photo-1594805368168-456e7a19ddaa?q=80&w=1000&auto=format&fit=crop',
        url: 'https://example.com/project2'
    },
    {
        id: '3',
        name: 'Real Estate Marketplace',
        description:
            'Platform connecting property buyers, sellers, and agents with interactive listing features.',
        screenshot:
            'https://images.unsplash.com/photo-1599643478518-a784e5dc4c8f?q=80&w=1000&auto=format&fit=crop',
        url: 'https://example.com/project3'
    },
    {
        id: '4',
        name: 'Task Management System',
        description:
            'Collaborative project management tool with real-time updates and progress tracking.',
        screenshot:
            'https://images.unsplash.com/photo-1641156001198-dd05c2982464?q=80&w=1000&auto=format&fit=crop',
        url: 'https://example.com/project4'
    },
    {
        id: '5',
        name: 'Social Media Dashboard',
        description:
            'Unified platform for managing multiple social media accounts and analytics.',
        screenshot:
            'https://images.unsplash.com/photo-1611162617213-7d7a39e9b1d7?q=80&w=1000&auto=format&fit=crop',
        url: 'https://example.com/project5'
    },
    {
        id: '6',
        name: 'Educational Platform',
        description:
            'Online learning system with course management and interactive learning materials.',
        screenshot:
            'https://images.unsplash.com/photo-1610484826967-09c5720778c7?q=80&w=1000&auto=format&fit=crop',
        url: 'https://example.com/project6'
    }
];
