import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
    selectMeetingRequested,
    selectRequestingMeeting,
    setShowRequestModal
} from '../../../../../redux/reducers/generic/project';
import RequestModal from './Requestmodal';
import { requestEnterpriseMeeting } from '../../../../../redux/actions/enterprise_widget/requestEnterpriseMeeting';
import { setMeetingRequested } from '../../../../../redux/reducers/generic/project';
import { selectEnterpriseWidgetProjectId } from '../../../../../redux/reducers/enterprise_widget/enterpriseWidget';
import { selectEnterpriseOrganization } from '../../../../../redux/reducers/enterprise/enterpriseOrganization';

export const RequestMeeting = () => {
    let dispatch = useDispatch();
    let meetingRequested = useSelector(selectMeetingRequested);
    let requestingMeeting = useSelector(selectRequestingMeeting);
    let enterpriseOrganization = useSelector(selectEnterpriseOrganization);

    const projectId = useSelector(selectEnterpriseWidgetProjectId);

    const onClick = () => {
        if (requestingMeeting) return;
        if (enterpriseOrganization?.calendlyLink)
            dispatch(setShowRequestModal(true));
        else {
            dispatch(requestEnterpriseMeeting(projectId));
            dispatch(setMeetingRequested(true));
        }
    };

    let scheduleMeetingButtonText =
        enterpriseOrganization?.scheduleMeetingButtonText ||
        'Schedule a Meeting';

    return (
        <div>
            {!meetingRequested ? (
                <button
                    onClick={onClick}
                    className="text-[var(--widget-text-color,white)] text-sm border border-2 border-[var(--widget-primary-color,#4F46E5)] hover:scale-105 duration-300 hover:opacity-90 focus:ring-4 focus:outline-none focus:ring-[var(--widget-primary-color,#4F46E5)]/30 font-medium rounded-lg text-sm px-6 py-3 text-center"
                >
                    {requestingMeeting
                        ? '📅 Requesting Meeting...'
                        : scheduleMeetingButtonText}
                </button>
            ) : (
                <button
                    disabled={true}
                    className="text-[var(--widget-text-color,#111827)] bg-gray-200 font-medium rounded-lg text-sm px-4 py-2 text-center sm:block"
                >
                    📅 Meeting Requested
                </button>
            )}
            <RequestModal />
        </div>
    );
};
