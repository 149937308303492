import { buttonVariants } from '../../Components/magicui/ui/button';
import { cn } from '../../Components/magicui/lib/utils';
import Marquee from '../../Components/magicui/marquee';
import { ChevronRight } from 'lucide-react';

export function GenerateJiraLinearRoadmaps() {
    return (
        <section id="cta">
            <div className="pt-4">
                <div className="container flex w-full flex-col items-center justify-center p-4">
                    <div className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-[2rem] border border-gray-800 p-10 py-14">
                        <div className="absolute rotate-[35deg]">
                            <Marquee
                                pauseOnHover
                                className="[--duration:20s]"
                                repeat={3}
                            >
                                {firstRow.map(review => (
                                    <ReviewCard key={review.img} {...review} />
                                ))}
                            </Marquee>
                            <Marquee
                                reverse
                                pauseOnHover
                                className="[--duration:20s]"
                                repeat={3}
                            >
                                {secondRow.map(review => (
                                    <ReviewCard key={review.img} {...review} />
                                ))}
                            </Marquee>
                        </div>
                        <div className="flex space-x-4" style={{ zIndex: 5 }}>
                            <div className="size-24 rounded-[2rem] border bg-white/10 p-3 shadow-2xl backdrop-blur-md dark:bg-black/10 lg:size-32">
                                <img
                                    src="/jira.svg"
                                    width={96}
                                    height={96}
                                    className="mx-auto text-black dark:text-white"
                                    alt="Jira Logo"
                                />
                            </div>
                            <div className="size-24 rounded-[2rem] border bg-white/10 p-3 shadow-2xl backdrop-blur-md dark:bg-black/10 lg:size-32">
                                <img
                                    src="/linear.svg"
                                    width={96}
                                    height={96}
                                    className="mx-auto text-black dark:text-white"
                                    alt="Linear Logo"
                                />
                            </div>
                        </div>
                        <div className="z-10 mt-4 flex flex-col items-center text-center text-black dark:text-white">
                            <h1 className="text-3xl font-bold lg:text-4xl">
                                Generate Jira and Linear Roadmaps in seconds
                            </h1>

                            <p className="mt-2">
                                Jira or Linear giving you headaches? Automate
                                your project management workflows with pre.dev.
                            </p>
                            <div className="flex flex-col items-center">
                                <a
                                    href="/projects"
                                    className={cn(
                                        buttonVariants({
                                            size: 'lg',
                                            variant: 'outline'
                                        }),
                                        'group mt-4 rounded-[2rem] px-6'
                                    )}
                                >
                                    Sign Up
                                    <ChevronRight className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
                                </a>
                                <a
                                    href="/projects"
                                    className="flex items-center mt-4"
                                >
                                    <img
                                        src="/predev.png"
                                        className="h-6"
                                        alt="Company Logo"
                                    />
                                    <span className="ml-2 self-center text-lg whitespace-nowrap text-white">
                                        pre.dev
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-full bg-gradient-to-b from-transparent to-white to-70% dark:to-black" />
                    </div>
                </div>
            </div>
        </section>
    );
}

const reviews = [
    {
        img: '/jiracta1.png'
    },

    {
        img: '/jiracta2.png'
    },

    {
        img: '/jiracta3.png'
    },
    {
        img: '/jiracta4.png'
    },
    {
        img: '/sowcta1.png'
    },
    {
        img: '/sowcta2.png'
    }
];

const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({ img }: { img: string }) => {
    return <img className="rounded-md h-[300px] w-auto" alt="" src={img} />;
};
