import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSignedUploadURL } from '../../redux/actions/solo/getSignedUploadURL';
import { v4 as uuidv4 } from 'uuid';
import { Upload, FileText, Check, AlertCircle, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '../Components/magicui/lib/utils';
import { toast } from 'sonner';

const UploadRFP = ({ setFileKey, isUploading, setUploading }) => {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [dragActive, setDragActive] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [uploadError, setUploadError] = useState(false);

    const handleDrag = e => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];
            if (isImageFile(file)) {
                showImageError();
                return;
            }
            handleFileUpload(file);
        }
    };

    const isImageFile = file => {
        const imageTypes = [
            'image/jpeg',
            'image/png',
            'image/gif',
            'image/bmp',
            'image/webp',
            'image/svg+xml'
        ];
        return (
            imageTypes.includes(file.type) ||
            file.name.match(/\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i)
        );
    };

    const showImageError = () => {
        toast.error('Image uploads are not allowed', {
            description:
                'Please upload document files only (PDF, DOCX, PPTX, TXT)',
            duration: 4000
        });
    };

    const handleFileChange = e => {
        const file = e.target.files[0];
        if (file) {
            if (isImageFile(file)) {
                showImageError();
                e.target.value = null;
                return;
            }
            handleFileUpload(file);
        }
    };

    const handleFileUpload = async file => {
        setFile(file);
        if (!file) return;
        setUploading(true);
        setUploadError(false);
        setUploadComplete(false);

        // Randomize filename
        let uniqueFilename = uuidv4() + file.name;

        if (file.size > 50 * 1024 * 1024) {
            setUploadError(true);
            setUploading(false);
            return;
        }

        try {
            // 1. Fetch the signed URL
            const signedUrl = await dispatch(
                getSignedUploadURL(uniqueFilename)
            );

            // 2. Upload the file using the signed URL
            await new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('PUT', signedUrl, true);
                xhr.upload.onprogress = function (event) {
                    if (event.lengthComputable) {
                        const percentComplete =
                            (event.loaded / event.total) * 100;
                        setProgress(percentComplete.toFixed(2));
                    }
                };
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        resolve();
                        setUploadComplete(true);
                    } else {
                        reject(new Error('File upload failed.'));
                        setUploadError(true);
                    }
                };
                xhr.onerror = function () {
                    reject(new Error('File upload failed.'));
                    setUploadError(true);
                };
                xhr.send(file);
            });

            // 3. Store the key (filename or path in S3) in a state
            setFileKey(uniqueFilename);
        } catch (error) {
            console.error('Upload error:', error);
            setUploadError(true);
        } finally {
            setUploading(false);
        }
    };

    const getFileNameDisplay = () => {
        if (!file) return '';
        return file.name.length > 20
            ? `${file.name.substring(0, 20)}...`
            : file.name;
    };

    const resetUpload = () => {
        setFile(null);
        setUploadComplete(false);
        setUploadError(false);
        setFileKey(null);
    };

    return (
        <div className="w-full">
            <div
                className={cn(
                    'px-2 relative border border-dashed rounded-md transition-all duration-200 flex items-center',
                    dragActive
                        ? 'border-blue-400 bg-blue-500/10'
                        : uploadComplete
                          ? 'border-green-500/50 bg-green-500/5'
                          : uploadError
                            ? 'border-red-500/50 bg-red-500/5'
                            : 'border-gray-600 hover:border-blue-400/50 hover:bg-blue-500/5'
                )}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
            >
                {/* File state indicator (left side) */}
                <div
                    className={cn(
                        'h-8 w-8 flex items-center justify-center rounded-md',
                        uploadComplete
                            ? 'bg-green-500/20'
                            : uploadError
                              ? 'bg-red-500/20'
                              : isUploading
                                ? 'bg-blue-500/20'
                                : ''
                    )}
                >
                    <AnimatePresence mode="wait">
                        {isUploading && (
                            <motion.div
                                key="uploading"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <FileText className="w-3.5 h-3.5 text-blue-400" />
                            </motion.div>
                        )}
                        {uploadComplete && !isUploading && (
                            <motion.div
                                key="complete"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <Check className="w-3.5 h-3.5 text-green-500" />
                            </motion.div>
                        )}
                        {uploadError && !isUploading && (
                            <motion.div
                                key="error"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <AlertCircle className="w-3.5 h-3.5 text-red-500" />
                            </motion.div>
                        )}
                        {!isUploading && !uploadComplete && !uploadError && (
                            <motion.div
                                key="upload"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <Upload className="w-3.5 h-3.5 text-[var(--widget-text-color,#3B82F6)]" />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>

                {/* Content (middle) */}
                <div className="flex-1 px-2 py-1.5 min-w-0">
                    <AnimatePresence mode="wait">
                        {!isUploading && !uploadComplete && !uploadError && (
                            <motion.label
                                key="idle"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                htmlFor="file-upload"
                                className="cursor-pointer flex items-center"
                            >
                                <div>
                                    <span className="text-xs text-[var(--widget-text-color,#3B82F6)] font-medium">
                                        Optional: Upload an RFP
                                    </span>
                                    <div className="text-[10px] text-[var(--widget-text-color,#3B82F6)] leading-tight">
                                        PDF, DOCX, PPTX, TXT (Max 50MB)
                                    </div>
                                </div>
                                <input
                                    id="file-upload"
                                    type="file"
                                    className="hidden"
                                    onChange={handleFileChange}
                                    accept=".pdf,.docx,.pptx,.xlsx,.odt,.odp,.ods,.txt"
                                />
                            </motion.label>
                        )}

                        {isUploading && (
                            <motion.div
                                key="uploading"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="w-full"
                            >
                                <div className="text-[10px] font-medium text-gray-300 mb-1 truncate">
                                    {getFileNameDisplay()}
                                </div>
                                <div className="w-full bg-gray-700 h-1 rounded-full overflow-hidden">
                                    <motion.div
                                        initial={{ width: 0 }}
                                        animate={{ width: `${progress}%` }}
                                        className="h-full bg-blue-500 rounded-full"
                                    />
                                </div>
                                <span className="text-[10px] text-gray-500 leading-tight">
                                    {progress}%
                                </span>
                            </motion.div>
                        )}

                        {uploadComplete && !isUploading && (
                            <motion.div
                                key="complete"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="w-full"
                            >
                                <div className="text-[10px] font-medium text-gray-500 truncate">
                                    {getFileNameDisplay()}
                                </div>
                                <div className="text-[10px] text-gray-500 leading-tight">
                                    Uploaded successfully
                                </div>
                            </motion.div>
                        )}

                        {uploadError && !isUploading && (
                            <motion.div
                                key="error"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="w-full"
                            >
                                <div className="text-[10px] font-medium text-gray-300 truncate">
                                    Error uploading file
                                </div>
                                <div className="text-[10px] text-gray-500 leading-tight">
                                    {file && file.size > 50 * 1024 * 1024
                                        ? 'File exceeds 50MB limit'
                                        : 'Upload failed. Try again.'}
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>

                {/* Action button (right side) */}
                {(uploadComplete || uploadError) && (
                    <button
                        type="button"
                        onClick={resetUpload}
                        className="h-8 w-8 flex items-center justify-center text-gray-500 hover:text-gray-300 transition-colors focus:outline-none"
                        aria-label={uploadError ? 'Try again' : 'Remove file'}
                    >
                        <X className="w-3.5 h-3.5" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default UploadRFP;
