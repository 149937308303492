const emojis: string[] = ['😎', '🤓', '🤩', '👨‍💻', '👩‍💻', '🧑‍💻', '🧑‍💻'];

interface Node {
    _id: string;
    id: string;
    type: string;
    reactPage?: string;
    data: {
        _id: string;
        id: string;
        label: string;
        hours: number;
        description: string;
        emoji: string;
        side: string;
        created: string;
        reactPage?: string;
        opacity: number;
    };
    position: { x: number; y: number };
}

interface Edge {
    id: string;
    source: string;
    target: string;
    data: {
        description: string;
    };
}

interface GraphData {
    version: string;
    side: string;
    _id: string;
    nodes: Node[];
    edges: Edge[];
}

interface InputNode {
    _id: { toString: () => string };
    id: string;
    label: string;
    hours: number;
    description: string;
    created: string;
    reactPage?: string;
}

interface InputEdge {
    _id: string;
    source: string;
    target: string;
    description: string;
}

interface InputGraph {
    version: string;
    side: string;
    _id: string;
    nodes: InputNode[];
    edges: InputEdge[];
}

export const getGraphData = (els: InputGraph | null): GraphData | null => {
    if (!els) {
        return null;
    }

    const data: GraphData = {
        version: els.version,
        side: els.side,
        _id: els._id,
        nodes: els.nodes.map((node, index) => ({
            _id: node._id.toString(),
            id: node.id,
            type: 'turbo',
            reactPage: node?.reactPage,
            data: {
                _id: node._id.toString(),
                id: node.id,
                label: node.label,
                hours: node.hours,
                description: node.description,
                emoji: '',
                side: els.side,
                created: node.created,
                reactPage: node?.reactPage,
                opacity: 100
            },
            position: { x: 0, y: 0 }
        })),
        edges: els['edges'].map(link => ({
            id: link._id,
            source: link.source,
            target: link.target,
            data: {
                description: link.description
            }
        }))
    };

    if (els.side === 'user flow') {
        // Assign unique emojis to branches stemming from the core node
        const coreNode = data.nodes.find(node => node.id === 'core');
        if (coreNode) {
            const branches = data.edges.filter(edge => edge.source === 'core');
            const assignedNodes = new Set<string>();

            branches.forEach((branch, index) => {
                const targetNode = data.nodes.find(
                    node => node.id === branch.target
                );
                if (targetNode && !assignedNodes.has(targetNode.id)) {
                    const emoji = emojis[index % emojis.length];
                    targetNode.data.emoji = emoji;
                    assignedNodes.add(targetNode.id);

                    // Assign the same emoji down the branch
                    const assignEmojiToBranch = (
                        nodeId: string,
                        emoji: string
                    ): void => {
                        const childBranches = data.edges.filter(
                            edge => edge.source === nodeId
                        );
                        childBranches.forEach(childBranch => {
                            const childNode = data.nodes.find(
                                node => node.id === childBranch.target
                            );
                            if (childNode && !assignedNodes.has(childNode.id)) {
                                childNode.data.emoji = emoji;
                                assignedNodes.add(childNode.id);
                                assignEmojiToBranch(childNode.id, emoji);
                            }
                        });
                    };

                    assignEmojiToBranch(targetNode.id, emoji);
                }
            });
        }
    }

    return data;
};

export const getDateTime = (): string => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };
    return new Date().toLocaleDateString(undefined, options);
};
