import { createSlice } from '@reduxjs/toolkit';

interface DownloadCursorState {
    modalOpen: boolean;
}

const initialState: DownloadCursorState = {
    modalOpen: false
};

export const downloadCursorSlice = createSlice({
    name: 'downloadCursor',
    initialState,
    reducers: {
        setDownloadCursorModalOpen: (state, action) => {
            state.modalOpen = action.payload;
        },
        toggleDownloadCursorModal: state => {
            state.modalOpen = !state.modalOpen;
        }
    }
});

export const { setDownloadCursorModalOpen, toggleDownloadCursorModal } =
    downloadCursorSlice.actions;

export const selectDownloadCursorModalOpen = (state: any) =>
    state.downloadCursor.modalOpen;

export default downloadCursorSlice.reducer;
