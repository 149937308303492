import { Spinner } from '../../../Components/Spinner';
import { moment } from '../../../../helpers/moment';
import { UserCircle } from 'lucide-react';
import { TypeAnimation } from 'react-type-animation';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSignedDownloadURL } from '../../../../redux/actions/solo/getSignedDownloadURL';
import { selectEnterpriseOrganization } from '../../../../redux/reducers/enterprise/enterpriseOrganization';
import Markdown from '../../../Components/Markdown';

export const ChatItem = ({ message, i, length }) => {
    let background = 'bg-[var(--widget-background-color,#FFFFFF)]';
    const dispatch = useDispatch();
    const [image_url, setImageUrl] = useState(null);

    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);

    const handleDownload = async e => {
        e.preventDefault();

        const signedUrl = await dispatch(
            getSignedDownloadURL(message.fileKeyS3)
        );

        // Redirect the user to the signed URL, this will start the download
        window.open(signedUrl, '_blank');
    };

    useEffect(() => {
        let asyncFunc = async () => {
            if (message.image_url && message.fileKeyS3) {
                const signedUrl = await dispatch(
                    getSignedDownloadURL(message.fileKeyS3)
                );
                setImageUrl(signedUrl);
            }
        };
        asyncFunc();
    }, []);

    return (
        <div key={i} className={`p-6 text-sm sm:text-base ${background}`}>
            <div className="flex items-center justify-between mb-4">
                {chatIcon(message, enterpriseOrganization)}
                {date(message)}
            </div>
            <div className="text-[var(--widget-text-color,gray-500)]">
                {message.fileKeyS3 && !image_url && (
                    <a
                        onClick={handleDownload}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cursor-pointer text-[var(--widget-text-color,blue-500)] hover:underline"
                    >
                        {message.fileKeyS3.substring(36)}
                    </a>
                )}
                {text(message, length)}
                {image_url && (
                    <img
                        src={image_url}
                        alt="Message Attachment"
                        className="max-w-full h-auto"
                    />
                )}
            </div>
        </div>
    );
};

let date = message => {
    let formattedDate = moment(message.datetime).format('MM/DD/YYYY, HH:mm:ss');
    return (
        <div className="text-xs text-[var(--widget-text-color,gray-500)]">
            {formattedDate}
        </div>
    );
};

let text = (message, length) => {
    let content = message.content;
    if (content) {
        content = message.content
            .replace(/\【\d+†source】/, '')
            .replace(/\【\d+†source】/g, '');
    }
    return message.isLoading && content === '' ? ( // if the message is loading, show the spinner
        <div className="flex flex-row items-center">
            <Spinner w={'4'} />
            <span className="ml-2 text-xs">
                Response may take a few moments...
            </span>
        </div>
    ) : length === 1 ? ( // if this is the first message, show the typing animation
        typeAnimation(message)
    ) : (
        <Markdown
            content={content}
            id={Math.random().toString(36).substring(7)}
        />
    );
};

let typeAnimation = message => {
    return (
        <TypeAnimation
            speed={99}
            cursor={true}
            sequence={[
                message.content,
                () => {
                    //get the div id="chatScroll" and scroll to the bottom
                    try {
                        let chatScroll = document.getElementById('chatScroll');
                        if (chatScroll)
                            chatScroll.scrollTop = chatScroll.scrollHeight;
                    } catch (error) {
                        console.log(error);
                    }
                }
            ]}
        />
    );
};

let iconMapping = enterpriseOrganization => {
    return {
        user: <UserCircle className="w-7 h-7 mr-2" />,
        assistant: (
            <img
                className="mr-2 w-7 h-7 rounded-full bg-black p-[2px]"
                src={
                    enterpriseOrganization?.chatAgentLogo ||
                    '/predevlogosquare.png'
                }
                alt={'bot'}
            />
        )
    };
};

let titleMapping = (message, enterpriseOrganization) => {
    let name = message.username || message.userEmail;
    let { role } = message;
    role = message.isLoading ? 'assistant' : role;
    if (role === 'user' && name) {
        return name;
    }
    if (role === 'user' && !name) {
        return 'You';
    }
    if (role === 'assistant') {
        if (enterpriseOrganization?.chatAgentName) {
            return enterpriseOrganization?.chatAgentName;
        }
        return 'pre.dev';
    } else return null;
};

let chatIcon = (message, enterpriseOrganization) => {
    return (
        <div className="inline-flex items-center mr-3 text-sm text-[var(--widget-text-color,black)]">
            {
                iconMapping(enterpriseOrganization)[
                    message.isLoading ? 'assistant' : message.role
                ]
            }
            {titleMapping(message, enterpriseOrganization)}
        </div>
    );
};
