import { InformationCircleIcon } from '@heroicons/react/24/outline';
import * as Tooltip from '@radix-ui/react-tooltip';

export const ProjectName = ({ project }) => {
    const name = project.name;
    const execSummary = project.execSummary;

    return (
        <div className="flex items-center space-x-3">
            <div className="flex items-center">
                <a className="hover:text-gray-600 flex items-center">
                    <span className="max-w-[300px] truncate text-ellipsis">
                        {name}
                    </span>
                    <Tooltip.Provider delayDuration={100}>
                        <Tooltip.Root>
                            <Tooltip.Trigger asChild>
                                <InformationCircleIcon className="h-5 w-5 ml-2 text-gray-400 cursor-help" />
                            </Tooltip.Trigger>
                            <Tooltip.Portal>
                                <Tooltip.Content
                                    className="max-w-md p-4 bg-white shadow-lg rounded-lg border border-gray-200"
                                    sideOffset={5}
                                >
                                    <p className="text-sm text-gray-700">
                                        {execSummary}
                                    </p>
                                    <Tooltip.Arrow className="fill-white" />
                                </Tooltip.Content>
                            </Tooltip.Portal>
                        </Tooltip.Root>
                    </Tooltip.Provider>
                </a>
            </div>
        </div>
    );
};
