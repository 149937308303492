import { Timeline } from '../../Components/magicui/timeline';

const Journey = () => {
    const data = [
        {
            title: 'Idea',
            content: (
                <div>
                    <p className="text-neutral-800 dark:text-neutral-200 text-md md:text-lg font-normal mb-8">
                        Describe your idea in a few sentences to pre.dev AI
                    </p>
                    <a
                        href="#"
                        onClick={() => {
                            const mainInput =
                                document.getElementById('mainInput');
                            if (mainInput) {
                                mainInput.focus();
                            }
                        }}
                    >
                        <img
                            src="/req1.png"
                            alt="hero template"
                            height={500}
                            className="cursor-pointer rounded-lg object-cover shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
                        />
                    </a>
                </div>
            )
        },
        {
            title: 'Prototype',
            content: (
                <div>
                    <p className="text-neutral-800 dark:text-neutral-200 text-md md:text-lg font-normal mb-8">
                        Our AI system will design the{' '}
                        <b className="text-blue-500">architecture</b>,{' '}
                        <b className="text-blue-500">roadmap</b>,{' '}
                        <b className="text-blue-500">documentation</b>,{' '}
                        <b className="text-blue-500">tech stack</b>, and give
                        you an exportable{' '}
                        <b className="text-blue-500">prototype</b> of the
                        codebase, saving you hundreds of hours and{' '}
                        <b className="text-xl text-green-500">
                            thousands of dollars
                        </b>{' '}
                        before any code is written.{' '}
                        <span className="inline-block mt-2 px-3 py-1 items-center bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-md shadow-lg">
                            <b>NEW:</b> Export your entire project as a{' '}
                            <b>Master Prompt</b> to use with any AI Coding
                            Agent!
                        </span>
                    </p>

                    <div className="grid grid-cols-1 gap-4">
                        <img
                            src="/prototype.png"
                            alt="prototype overview"
                            width={800}
                            height={800}
                            className="rounded-lg object-cover shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
                        />
                    </div>
                </div>
            )
        },
        {
            title: 'Match with Developers',
            content: (
                <div>
                    <p className="text-neutral-800 dark:text-neutral-200 text-md md:text-lg font-normal mb-4">
                        We will help you{' '}
                        <b className="text-blue-500">filter the scope</b> of
                        your development work instantly and
                        <b className="text-blue-500">
                            {' '}
                            prioritize the most important features
                        </b>{' '}
                        to launch within your{' '}
                        <b className="text-blue-500">budget</b>. Then, we will{' '}
                        <b className="text-blue-500">match you</b> with a
                        professionally-vetted development team that has the
                        skillset necessary to deliver your{' '}
                        <b className="text-blue-500">
                            exact product requirements
                        </b>
                        , for the{' '}
                        <b className="text-blue-500">
                            best price on the market.
                        </b>
                    </p>

                    <div className="grid grid-cols-2 gap-4">
                        <img
                            src="/full_budget.png"
                            alt="budget planning"
                            width={500}
                            height={500}
                            className="rounded-lg shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
                        />
                        <img
                            src="/schedule_email.png"
                            alt="developer matching"
                            width={500}
                            height={500}
                            className="rounded-lg border-4 border border-gray-700 p-4 bg-white shadow-[0_0_24px_rgba(34,_42,_53,_0.06),_0_1px_1px_rgba(0,_0,_0,_0.05),_0_0_0_1px_rgba(34,_42,_53,_0.04),_0_0_4px_rgba(34,_42,_53,_0.08),_0_16px_68px_rgba(47,_48,_55,_0.05),_0_1px_0_rgba(255,_255,_255,_0.1)_inset]"
                        />
                    </div>
                </div>
            )
        }
    ];
    return (
        <div id="how-it-works" className="w-full dark">
            <Timeline data={data} />
            <div className="flex flex-col items-center mt-16 mb-12">
                <h3 className="text-2xl font-bold text-white mb-4">
                    Ready to Build Your Product?
                </h3>
                <p className="text-gray-300 max-w-2xl text-center mb-8">
                    Get an initial live prototype, detailed project plan, budget
                    breakdown, and connect with pre-vetted developers - all in
                    one place.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                    <a
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            setTimeout(() => {
                                const mainInput =
                                    document.getElementById('mainInput');
                                if (mainInput) {
                                    mainInput.focus();
                                }
                            }, 800);
                        }}
                        className="text-white bg-gradient-to-r from-blue-600 to-blue-800 hover:from-blue-500 hover:to-blue-700 font-semibold rounded-lg px-8 py-4 text-lg transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-blue-500/30 flex items-center justify-center"
                    >
                        Start Your Project
                        <svg
                            className="w-5 h-5 ml-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </a>
                    <a
                        href="#pricing"
                        className="text-blue-400 border border-blue-400 hover:bg-blue-900/20 font-semibold rounded-lg px-8 py-4 text-lg transition-all duration-300 flex items-center justify-center"
                    >
                        View Pricing
                    </a>
                </div>
                <div className="mt-6 flex items-center gap-2">
                    <svg
                        className="w-5 h-5 text-green-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                    <span className="text-gray-300 text-sm">
                        No credit card required to start
                    </span>
                </div>
            </div>
        </div>
    );
};
export default Journey;
