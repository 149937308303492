import * as Slider from '@radix-ui/react-slider';
import { useProject } from '../../../context-providers/Project';
import { useSubscriptionPayment } from '../../../context-providers/SubscriptionPayment';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

export const getUrgencyLabel = value => {
    if (value === 0) return 'No Rush';
    if (value <= 25) return 'Within 6 Months';
    if (value <= 50) return 'Within 3 Months';
    if (value <= 75) return 'Within 1 Month';
    return '🔥 ASAP 🔥';
};

export const UrgencySlider = ({
    urgency,
    setUrgency,
    textColor = 'text-gray-300'
}) => {
    const { project } = useProject();
    const { isHomePage, isSolo } = useSubscriptionPayment();
    const { accessTokenPayload } = useSessionContext();
    const userEmail = accessTokenPayload?.email;
    const canEdit = project?.userEmail === userEmail || isHomePage || isSolo;

    return (
        canEdit && (
            <div className="w-full bg-gradient-to-br from-gray-900 to-black rounded-lg shadow-lg border-2 border-gray-700 py-4 px-4">
                <div className={`flex justify-between items-center mb-2`}>
                    <span
                        className={`block text-left text-sm font-medium ${textColor} `}
                    >
                        Project Start Date: {getUrgencyLabel(urgency)}
                    </span>
                </div>
                <Slider.Root
                    className="relative flex items-center select-none touch-none w-full h-8"
                    value={[urgency]}
                    max={100}
                    min={0}
                    step={25}
                    disabled={!canEdit}
                    onValueChange={value => setUrgency(value[0])}
                >
                    <Slider.Track className="relative bg-gray-500 grow rounded-full h-[6px] overflow-hidden">
                        <div className="absolute inset-0 bg-stars-pattern opacity-30"></div>
                        <Slider.Range
                            className="absolute rounded-full h-full shadow-lg transition-colors duration-300"
                            style={{
                                background:
                                    urgency === 100
                                        ? 'linear-gradient(to right, #ff4d4d, #ff0000)'
                                        : `linear-gradient(
                                    to right,
                                    hsl(${
                                        120 - (urgency / 100) * 120
                                    }, 70%, 50%),
                                    hsl(${
                                        120 - (urgency / 100) * 120 + 30
                                    }, 70%, 50%)
                                )`
                            }}
                        />
                    </Slider.Track>
                    <Slider.Thumb
                        className={`z-10 block w-6 h-6 bg-white border-2 border-black shadow-xl rounded-full hover:scale-110 focus:outline-none focus:ring-4 transition-transform duration-300 ${
                            urgency === 100 ? 'border-red-500' : ''
                        }`}
                        aria-label="Urgency"
                    />
                </Slider.Root>
                <p className="text-xs text-gray-400">
                    More urgency means faster response times from developers.
                </p>
            </div>
        )
    );
};
