'use client';

import {
    AnimatePresence,
    cubicBezier,
    motion,
    useAnimation,
    useInView
} from 'framer-motion';
import { useEffect, useRef } from 'react';

const cardImage = [
    {
        id: 1,
        title: 'Landing',
        link: '#',
        image: 'https://avatar.vercel.sh/tom'
    },
    {
        id: 2,
        title: 'Signin',
        link: '#',
        image: 'https://avatar.vercel.sh/chris'
    },
    {
        id: 3,
        title: 'Signup',
        link: '#',
        image: 'https://avatar.vercel.sh/pj'
    },
    {
        id: 4,
        title: 'Home',
        link: '#',
        image: 'https://avatar.vercel.sh/scott'
    },
    {
        id: 5,
        title: 'Feed',
        link: '#',
        image: 'https://avatar.vercel.sh/yehuda'
    },
    {
        id: 6,
        title: 'Profile',
        link: '#',
        image: 'https://avatar.vercel.sh/ezra'
    },
    {
        id: 7,
        title: 'Settings',
        link: '#',
        image: 'https://avatar.vercel.sh/ivey'
    },
    {
        id: 8,
        title: 'New Post',
        link: '#',
        image: 'https://avatar.vercel.sh/evan'
    },
    {
        id: 9,
        title: 'Edit Post',
        link: '#',
        image: 'https://avatar.vercel.sh/van'
    }
];

export function CtaSection() {
    const containerRef = useRef(null);
    const inView = useInView(containerRef, { amount: 0.25 });
    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [inView, controls]);

    return (
        <div className="dark flex h-full transform-gpu flex-col items-center justify-between gap-5 rounded-lg border border-neutral-200 bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)] dark:bg-transparent dark:backdrop-blur-sm dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset] xl:flex-row">
            <div className="flex w-full flex-col items-start justify-between gap-y-10 p-10 xl:h-full xl:w-1/2">
                <h2 className="text-3xl font-semibold text-white">
                    <span className="text-blue-600">pre.dev</span> is your AI
                    Product Architect. <br /> Accelerate{' '}
                    <span className="text-blue-600">ideas</span> to{' '}
                    <span className="text-blue-600">development</span>.
                </h2>
                <a
                    href="/projects"
                    style={{ borderRadius: '0.5rem' }}
                    className=" text-primary-foreground shadow inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 opacity-[0.9] mt-4 inline-flex items-center justify-center px-5 py-3 mr-3 font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-900"
                >
                    Sign Up
                    <svg
                        className="w-5 h-5 ml-2 -mr-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </a>
            </div>
            <div className="relative w-full overflow-hidden xl:w-1/2">
                <div
                    className="pointer-events-none absolute inset-x-0 top-0 h-1/3 bg-gradient-to-b from-white dark:from-black"
                    style={{ zIndex: 5 }}
                ></div>
                <div
                    ref={containerRef}
                    className="relative -right-[50px] -top-[100px] grid max-h-[450px] grid-cols-3 gap-5 [transform:rotate(-15deg)translateZ(10px);]"
                >
                    <AnimatePresence>
                        {cardImage.map((card, index) => (
                            <motion.div
                                variants={{
                                    hidden: { opacity: 0, scale: 0.96, y: 25 },
                                    visible: { opacity: 1, scale: 1, y: 0 }
                                }}
                                initial="hidden"
                                animate={controls}
                                exit={{
                                    opacity: 0,
                                    scale: 0,
                                    transition: {
                                        duration: 0.1,
                                        ease: cubicBezier(0.22, 1, 0.36, 1)
                                    }
                                }}
                                transition={{
                                    duration: 0.2,
                                    ease: cubicBezier(0.22, 1, 0.36, 1),
                                    delay: index * 0.04
                                }}
                                key={card.id}
                                className="flex flex-col items-center gap-y-2 rounded-md bg-white/5 p-5"
                            >
                                <img
                                    src={card.image}
                                    alt={card.title}
                                    width={80}
                                    height={64}
                                    className="h-16 w-20 rounded-lg object-cover"
                                />
                                <p className="text-sm dark:text-neutral-200/50">
                                    {card.title}
                                </p>
                                <a className="rounded-md border border-neutral-700/50 bg-neutral-600 p-2 py-0.5 text-white shadow-xl drop-shadow-lg hover:bg-neutral-500 dark:border-neutral-400/20 dark:bg-gray-500">
                                    Generate
                                </a>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
}

export default CtaSection;
