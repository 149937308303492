import { useRef, useState } from 'react';
import { Suggest } from './Suggest';
import { AttachFile } from './AttachFile';
import { Input } from './Input';
import { SuggestWithInput } from './SuggestWithInput';

export const ChatInput = ({ isSolo, isWidget, isEnterprise, isShare }) => {
    const textareaRef = useRef(null);
    const [answer, setAnswer] = useState('');
    const [image_url, setImageUrl] = useState(null);
    const [fileKeyS3, setFileKeyS3] = useState(null);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isUploading, setUploading] = useState(false);
    const [suggestion, setSuggestion] = useState('');
    const [enhancing, setEnhancing] = useState(false);

    return (
        <div
            className="px-4 left-0 w-full border-t bg-[var(--widget-background-color,#FFFFFF)]"
            id="chatInput"
        >
            <Input
                isShare={isShare}
                isSolo={isSolo}
                isWidget={isWidget}
                image_url={image_url}
                setImageUrl={setImageUrl}
                fileKeyS3={fileKeyS3}
                setFileKeyS3={setFileKeyS3}
                setFile={setFile}
                setProgress={setProgress}
                isUploading={isUploading}
                setUploading={setUploading}
                answer={answer}
                setAnswer={setAnswer}
                textareaRef={textareaRef}
                progress={progress}
                file={file}
                suggestion={suggestion}
                setSuggestion={setSuggestion}
                enhancing={enhancing}
                setEnhancing={setEnhancing}
            />

            <Suggest
                isShare={isShare}
                textareaRef={textareaRef}
                setAnswer={setAnswer}
                answer={answer}
                suggestion={suggestion}
                setSuggestion={setSuggestion}
            />
        </div>
    );
};
