import {
    MegaphoneIcon,
    SparklesIcon,
    WindowIcon,
    ServerIcon,
    UserGroupIcon,
    CodeBracketIcon,
    BoltIcon,
    UserPlusIcon,
    ChartBarIcon
} from '@heroicons/react/24/outline';
import { MagicWandIcon, PersonIcon } from '@radix-ui/react-icons';

export let basicPlan = {
    id: 'basic',
    name: 'Basic',
    description: 'A free plan to try the platform',
    features: [
        'No credit card required',
        '2 project limit w/ max 10 chat responses',
        'Basic Product Discovery',
        'Basic Architecture Analysis',
        'Match with leading developers'
    ],
    prototypeGenerations: {
        quantity: '2'
    },
    missingFeatures: [
        'Unlimited Projects',
        'Jira/Linear Integration',
        'Master Prompt Export'
    ],
    isMostPopular: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    textColor: 'text-white'
};

export let basicPlanEnterprise = {
    id: 'basic',
    name: 'Basic',
    description: 'A free plan to try the platform',
    features: [
        'No credit card required',
        '2 project limit w/ max 10 chat responses',
        'Basic Product Discovery',
        'Basic Architecture Analysis',
        'Basic Product Documentation',
        'Basic Product Roadmap'
    ],
    prototypeGenerations: {
        quantity: '2'
    },
    missingFeatures: [
        'Unlimited Projects',
        'Jira/Linear Integration',
        'Master Prompt Export',
        'Lead Generation'
    ],
    isMostPopular: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    textColor: 'text-white'
};

let soloPlusPlan = {
    id: 'SoloPlus',
    name: 'Solo Plus',
    description: 'An intro plan for individuals',
    features: [
        'Unlimited Projects',
        'Unlimited Chat',
        'Estimated Budget',
        'Deep Architecture Analysis',
        'Enhanced Product Documentation',
        'Full Product Roadmap',
        'Priority Match with Developers',
        'Master Prompt Export'
    ],
    prototypeGenerations: {
        frequency: 'Daily',
        quantity: '5'
    },
    monthlyPrice: 1400,
    yearlyPrice: 14000,
    textColor: 'text-blue-400'
};

export let soloPremiumPlan = {
    id: 'SoloPremium',
    name: 'Solo Premium',
    description: 'The ultimate plan for individuals',
    features: [
        'Unlimited Projects',
        'Unlimited Chat',
        'Estimated Budget',
        'Deep Architecture Analysis',
        'Enhanced Product Documentation',
        'Full Product Roadmap',
        'Priority Match with Developers',
        'Master Prompt Export',
        'Jira/Linear Integration'
    ],
    prototypeGenerations: {
        frequency: 'Daily',
        quantity: '25'
    },
    monthlyPrice: 4900,
    yearlyPrice: 49900,
    textColor: 'text-blue-500'
};

let enterprisePremiumPlan = {
    id: 'EnterprisePremium',
    name: 'Enterprise Premium',
    description: 'An enterprise plan made for teams',
    features: [
        'Unlimited Projects',
        'Unlimited Chat',
        'Estimated Budget',
        'Deep Architecture Analysis',
        'Enhanced Product Documentation',
        'Full Product Roadmap',
        'Jira/Linear Integration',
        'Master Prompt Export',
        'Advanced Collaboration Features',
        'Configurable Knowledge Base',
        'Data Security and Compliance'
    ],
    prototypeGenerations: {
        frequency: 'Daily',
        quantity: '25'
    },
    addons: [
        'Lead Gen Widget',
        'Developer Directory Listing',
        'Unlimited Prototypes',
        'Lead Gen Database'
    ],
    monthlyPrice: 9900,
    yearlyPrice: 99900,
    textColor: 'text-green-500',
    perUser: true
};

let agencyBasicPlan = {
    id: 'LeadGenBasic',
    name: 'Agency Basic',
    description: 'Essential tools for agencies',
    features: [
        'Unlimited Projects',
        'Unlimited Chat',
        'Estimated Budget',
        'Deep Architecture Analysis',
        'Enhanced Product Documentation',
        'Full Product Roadmap',
        'Jira/Linear Integration',
        'Master Prompt Export',
        'Advanced Collaboration Features',
        'Configurable Knowledge Base',
        'Data Security and Compliance',
        '1 Enterprise Seat Included'
    ],
    prototypeGenerations: {
        frequency: 'Daily',
        quantity: '25'
    },
    premiumFeatures: [
        {
            title: 'Lead Gen Widget',
            description: 'Integrate pre.dev lead gen widget on your website',
            icon: textColor => (
                <WindowIcon
                    className={`h-4 w-4 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            )
        }
    ],
    monthlyPrice: 24900,
    yearlyPrice: 249900,
    textColor: 'text-purple-500'
};

let agencyDeluxePlan = {
    id: 'LeadGenPro',
    name: 'Agency Deluxe',
    description: 'Premium tools for your agency',
    features: [
        'Unlimited Projects',
        'Unlimited Chat',
        'Estimated Budget',
        'Deep Architecture Analysis',
        'Enhanced Product Documentation',
        'Full Product Roadmap',
        'Jira/Linear Integration',
        'Master Prompt Export',
        'Advanced Collaboration Features',
        'Configurable Knowledge Base',
        'Data Security and Compliance',
        '1 Enterprise Seat Included'
    ],
    prototypeGenerations: {
        frequency: 'Daily',
        quantity: 'Unlimited'
    },
    premiumFeatures: [
        {
            title: 'Unlimited Prototypes',
            description: 'Generate unlimited prototypes for your projects',
            icon: textColor => (
                <CodeBracketIcon
                    className={`h-4 w-4 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            )
        },
        {
            title: 'Lead Gen Widget',
            description: 'Integrate pre.dev lead gen widget on your website',
            icon: textColor => (
                <WindowIcon
                    className={`h-4 w-4 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            )
        },
        {
            title: 'Developer Directory Listing',
            description: 'Get featured in the pre.dev Developer Directory',
            icon: textColor => (
                <UserGroupIcon
                    className={`h-4 w-4 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            )
        },
        {
            title: 'Lead Gen Database',
            description: 'Access curated projects looking to hire developers',
            icon: textColor => (
                <ServerIcon
                    className={`h-4 w-4 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            )
        },
        {
            title: 'Automated Lead Follow-Up (Coming Soon)',
            description:
                'Automatically connect with your leads and convert them into customers',
            icon: textColor => (
                <PersonIcon
                    className={`h-4 w-4 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            )
        }
    ],
    monthlyPrice: 99900,
    yearlyPrice: 999900,
    halfOffFirstMonth: true,
    textColor: 'text-indigo-600'
};

export const prices = ({
    basic,
    soloPlus,
    soloPremium,
    enterprisePremium,
    agencyBasic,
    agencyDeluxe
}) => {
    let plans = [];

    if (basic) {
        plans.push(basicPlan);
    }

    if (soloPlus) {
        plans.push(soloPlusPlan);
    }

    if (soloPremium) {
        plans.push(soloPremiumPlan);
    }

    if (enterprisePremium) {
        if (agencyBasic || agencyDeluxe) {
            delete enterprisePremiumPlan.addons;
        }
        plans.push(enterprisePremiumPlan);
    }

    if (agencyBasic) {
        plans.push(agencyBasicPlan);
    }

    if (agencyDeluxe) {
        plans.push(agencyDeluxePlan);
    }

    return plans;
};
