import React, { useState, useEffect } from 'react';
import { getCountryFlagEmoji } from '../../EnterpriseHome/ClientProjects/ProjectsList/item';
import { countryCodes } from './countryCodes';
import * as Popover from '@radix-ui/react-popover';

// Reorder countryCodes to prioritize US over Canada
const reorderedCountryCodes = [
    countryCodes.find(c => c.code === 'US'),
    ...countryCodes.filter(c => c.code !== 'US')
];

export const PhoneInput = ({ value, onChange, disabled }) => {
    const [selectedCountry, setSelectedCountry] = useState(
        reorderedCountryCodes[0]
    );
    const [phoneNumber, setPhoneNumber] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (value) {
            let bestMatch = reorderedCountryCodes[0];
            for (const country of reorderedCountryCodes) {
                if (value.startsWith(country.dialCode)) {
                    bestMatch = country;
                    break;
                }
            }
            setSelectedCountry(bestMatch);
            setPhoneNumber(value.replace(bestMatch.dialCode, '').trim());
        } else {
            setSelectedCountry(reorderedCountryCodes[0]);
            setPhoneNumber('');
        }
    }, [value]);

    const handleNumberChange = event => {
        const newNumber = event.target.value.replace(/[^\d]/g, '');
        setPhoneNumber(newNumber);
        if (onChange) {
            onChange(`${selectedCountry.dialCode}${newNumber}`);
        }
    };

    const handleCountrySelect = country => {
        setSelectedCountry(country);
        if (onChange) {
            onChange(`${country.dialCode}${phoneNumber}`);
        }
    };

    const filteredCountries = reorderedCountryCodes.filter(
        country =>
            country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            country.dialCode.includes(searchQuery) ||
            country.code.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="flex rounded-md shadow-sm w-full">
            <div className="relative flex flex-row items-stretch focus-within:z-10">
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <button
                            className="flex flex-row items-center gap-x-1 px-2 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 hover:bg-gray-100"
                            disabled={disabled}
                        >
                            {getCountryFlagEmoji(selectedCountry.code)}{' '}
                            <span className="text-sm text-gray-500">
                                {selectedCountry.dialCode}
                            </span>
                        </button>
                    </Popover.Trigger>
                    <Popover.Portal>
                        <Popover.Content className="bg-white rounded-lg shadow-lg border border-gray-200 w-64 p-2 z-50">
                            <input
                                type="text"
                                placeholder="Search countries..."
                                className="w-full px-2 py-1 border border-gray-300 rounded mb-2 text-sm"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                            />
                            <div className="max-h-64 overflow-y-auto">
                                {filteredCountries.map(country => (
                                    <button
                                        key={country.code}
                                        className="w-full text-left px-2 py-1 hover:bg-gray-100 rounded text-sm flex items-center gap-2"
                                        onClick={() => {
                                            handleCountrySelect(country);
                                            setSearchQuery('');
                                        }}
                                    >
                                        <span>
                                            {getCountryFlagEmoji(country.code)}
                                        </span>
                                        <span>{country.name}</span>
                                        <span className="text-gray-500 ml-auto">
                                            {country.dialCode}
                                        </span>
                                    </button>
                                ))}
                            </div>
                        </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
            <input
                type="tel"
                name="phone-number"
                id="phone-number"
                className={`
                    flex-1
                    block
                    w-full
                    min-w-0
                    border
                    border-gray-300
                    rounded-r-md
                    px-3
                    py-2
                    text-[var(--widget-text-color,#111827)]
                    focus:outline-none focus:ring-indigo-500 focus:border-indigo-500
                    disabled:bg-gray-50 disabled:text-gray-500
                    placeholder:text-gray-400
                `}
                placeholder="Phone number"
                value={phoneNumber}
                onChange={handleNumberChange}
                disabled={disabled}
            />
        </div>
    );
};
