import React, { useState } from 'react';

/**
 * A responsive YouTube video component that ensures high quality playback
 * by providing a thumbnail with a play button that opens the video in a new tab
 * with forced high quality parameters
 *
 * @param {Object} props - Component props
 * @param {string} props.videoId - YouTube video ID
 * @param {string} props.title - Video title for accessibility
 * @param {string} props.className - Optional additional CSS classes
 * @returns {JSX.Element} A responsive YouTube thumbnail with play button
 */
const YouTubeEmbed = ({
    videoId,
    title = 'YouTube video player',
    className = ''
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    // Get high-resolution thumbnail URL
    const thumbnailUrl = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
    const fallbackThumbnailUrl = `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;

    // Direct YouTube URL with high quality parameters
    const youtubeUrl = `https://www.youtube.com/watch?v=${videoId}&vq=hd1080&hd=1`;

    // Handle thumbnail load error by switching to fallback
    const handleImageError = e => {
        e.target.onerror = null; // Prevent infinite loop
        e.target.src = fallbackThumbnailUrl;
    };

    // Standard embedded player URL (as a fallback if we need it)
    const embedUrl = `https://www.youtube.com/embed/${videoId}?rel=0&vq=hd1080&hd=1`;

    return (
        <div
            className={`relative w-full overflow-hidden ${className}`}
            style={{ paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }}
        >
            {/* Thumbnail with play button overlay */}
            <div className="absolute top-0 left-0 w-full h-full">
                <a
                    href={youtubeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block relative w-full h-full"
                    aria-label={`Play ${title} in high quality on YouTube`}
                >
                    <img
                        src={thumbnailUrl}
                        alt={title}
                        className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-300 ${
                            imageLoaded ? 'opacity-100' : 'opacity-0'
                        }`}
                        onLoad={() => setImageLoaded(true)}
                        onError={handleImageError}
                    />

                    {/* Fallback color while image loads */}
                    <div
                        className={`absolute top-0 left-0 w-full h-full bg-black transition-opacity duration-300 ${
                            imageLoaded ? 'opacity-0' : 'opacity-100'
                        }`}
                    />

                    {/* Play button overlay */}
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                        <div className="group">
                            {/* Play button with hover effect */}
                            <div className="w-20 h-20 bg-black bg-opacity-60 rounded-full flex items-center justify-center transition-all duration-300 group-hover:bg-red-600 group-hover:scale-110">
                                <div className="w-0 h-0 border-t-[10px] border-t-transparent border-b-[10px] border-b-transparent border-l-[18px] border-l-white ml-2"></div>
                            </div>

                            {/* "Watch in HD" text */}
                            {/* <p className="absolute mt-2 text-center w-full text-white text-sm bg-black bg-opacity-60 py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                Watch in HD
                            </p> */}
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default YouTubeEmbed;
