import { Switch } from '@headlessui/react';
import { Spinner } from '../../../Components/Spinner';

export const SwitchDefault = ({ enabled, setEnabled, loading }) => {
    if (loading) {
        return <Spinner w="5" />;
    }

    return (
        <Switch
            checked={enabled}
            onChange={setEnabled}
            className={`${
                enabled ? 'bg-green-600' : 'bg-gray-300'
            } relative inline-flex h-[28px] w-[56px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-300 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-opacity-75 shadow-sm hover:${
                enabled ? 'bg-green-500' : 'bg-gray-400'
            }`}
        >
            <span className="sr-only">{enabled ? 'Enabled' : 'Disabled'}</span>
            <span
                aria-hidden="true"
                className={`${
                    enabled ? 'translate-x-7' : 'translate-x-0.5'
                } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-md ring-0 transition-all duration-300 ease-in-out my-[2px] ${
                    enabled ? 'scale-110' : ''
                }`}
            />
        </Switch>
    );
};
