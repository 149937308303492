import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import autosize from 'autosize';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'react-textarea-autosize';
import { useProject } from '../../../../../context-providers/Project';
import { handleChatSubmit } from '../../../../../redux/actions/solo/handleChatSubmit';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { AttachFile } from './AttachFile';
import { useSubscriptionPayment } from '../../../../../context-providers/SubscriptionPayment';
import { SuggestWithInput } from './SuggestWithInput';
import trackEvent from '../../../../../helpers/trackEvent';
import { SkipQuestions } from './SkipQuestions';

export const Input = ({
    isSolo,
    isWidget,
    image_url,
    setImageUrl,
    fileKeyS3,
    setFileKeyS3,
    setFile,
    setProgress,
    setUploading,
    isUploading,
    textareaRef,
    answer,
    isShare,
    setAnswer,
    progress,
    file,
    suggestion,
    setSuggestion
}) => {
    let { chatHistory, projectId } = useProject();
    let { isSubbed } = useSubscriptionPayment();

    const [isMobile, setIsMobile] = useState(
        /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
        };
        window.addEventListener('resize', handleResize);
        handleResize(); // Call initially
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let waiting =
        chatHistory.waitingForChatResponse || chatHistory.waitingForSuggestion;
    let chat = chatHistory.data;

    const { accessTokenPayload } = useSessionContext();

    const dispatch = useDispatch();

    const [params] = useSearchParams();
    const username = params.get('username');

    const onChange = e => {
        setAnswer(e.target.value);
        if (suggestion) {
            setSuggestion('');
        }
        autosize(document.querySelectorAll('textarea'));
    };

    const handleSuggestion = () => {
        trackEvent('useSuggestion', {
            userEmail: accessTokenPayload?.email,
            userId: accessTokenPayload?.userId
        });
        setAnswer(suggestion);
        setSuggestion('');
    };

    // various effects for the chatinput
    useEffect(() => {
        if (!waiting && textareaRef && textareaRef.current) {
            autosize(textareaRef);
            if (window.innerWidth > 1024) textareaRef.current.focus(); // Refocus the textarea
        }
    }, [chat, waiting]);

    const soloNoGo = isSolo && !isSubbed && chat.length > 10;
    const enterpriseNoGo = !isSolo && !isSubbed && chat.length > 10;

    // define submit event handler
    const handleAnswerSubmit = async (event, ta) => {
        event.preventDefault();

        if (isShare) {
            toast.error('Please login to continue chatting');
            return;
        }

        if (soloNoGo) {
            toast.warning('Upgrade Account to continue chatting');
            return;
        }

        if (enterpriseNoGo) {
            toast.warning('Upgrade Account to continue chatting');
            return;
        }

        if (!answer) answer = document.getElementById('answer').value;

        if ((answer === '' || !answer) && !fileKeyS3 && !image_url) return;
        if (waiting) return;

        try {
            dispatch(
                handleChatSubmit({
                    answer,
                    image_url,
                    fileKeyS3,
                    projectId,
                    userEmail: accessTokenPayload?.email,
                    username,
                    isEnterprise: !isSolo,
                    isWidget
                })
            );
            setAnswer('');
            setFile(null);
            setFileKeyS3(null);
            setImageUrl(null);
            setProgress(0);
            trackEvent('answerSubmitted', {
                userEmail: accessTokenPayload?.email,
                userId: accessTokenPayload?.userId
            });

            autosize.destroy(document.querySelectorAll('textarea'));
            textareaRef.current.style.height = '40px';
        } catch (err) {
            console.log(err);
            toast.error('Error submitting answer');
        }
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleAnswerSubmit(e, 'ta');
        }
        if (e.key === 'Tab' && suggestion && !isMobile) {
            e.preventDefault();
            handleSuggestion();
        }
    };

    const [enhancing, setEnhancing] = useState(false);

    return (
        <form
            className="my-2 flex flex-col gap-2 w-full"
            onSubmit={handleAnswerSubmit}
        >
            <div className="relative bg-[var(--widget-background-color,#FFFFFF)] border border-gray-200 rounded-xl px-4 pb-2 pt-4">
                <textarea
                    id="answer"
                    placeholder={suggestion ? suggestion : 'Ask pre.dev...'}
                    onKeyDown={handleKeyDown}
                    style={{
                        lineHeight: '20px'
                    }}
                    ref={textareaRef}
                    onChange={onChange}
                    value={answer}
                    disabled={enhancing || waiting}
                    className="p-0 w-full text-[var(--widget-text-color,#111827)] resize-none min-h-[60px] max-h-[120px] bg-transparent border-none focus:outline-none focus:border-none focus:ring-0"
                    rows="1"
                />

                <div className="flex items-center justify-between gap-2 mt-2">
                    <div className="flex items-center gap-2">
                        <AttachFile
                            isShare={isShare}
                            setImageUrl={setImageUrl}
                            setFileKeyS3={setFileKeyS3}
                            setFile={setFile}
                            setProgress={setProgress}
                            progress={progress}
                            setUploading={setUploading}
                            isUploading={isUploading}
                            file={file}
                            from="widget"
                        />
                        <div className="flex items-center gap-2">
                            {chatHistory.waitingForSuggestion && !answer && (
                                <div className="text-xs text-gray-600">
                                    Generating suggestion...
                                </div>
                            )}
                            {suggestion && !answer && (
                                <div
                                    className={`text-xs text-gray-500 py-1 cursor-pointer border border-gray-200 rounded-md w-fit px-2`}
                                    onClick={handleSuggestion}
                                >
                                    {isMobile
                                        ? 'Tap here to use suggestion'
                                        : 'Press Tab ⇥ to use suggestion'}
                                </div>
                            )}

                            <SuggestWithInput
                                answer={answer}
                                setAnswer={setAnswer}
                                enhancing={enhancing}
                                setEnhancing={setEnhancing}
                            />
                        </div>
                    </div>

                    <button
                        type="submit"
                        disabled={waiting || isUploading}
                        className={`rounded-lg px-4 py-2 text-sm font-medium text-white ${
                            waiting ||
                            isUploading ||
                            (answer === '' && !fileKeyS3 && !image_url)
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-[var(--widget-primary-color,#4F46E5)] hover:opacity-90 focus:ring-4 focus:outline-none focus:ring-[var(--widget-primary-color,#4F46E5)]/30'
                        }`}
                    >
                        <PaperAirplaneIcon className="w-5 h-5" />
                    </button>
                </div>
            </div>
        </form>
    );
};
