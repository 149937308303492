import { cn } from '../Components/magicui/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { AlignJustify, XIcon, ChevronDown } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UseCases } from '../UseCases';
import { setRedirectURL, talkToPreDev } from '../../constants';
import { useEnterpriseOrganizations } from '../../context-providers/EnterpriseOrganizations';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import {
    selectShowBudgetModal,
    selectShowRequestModal
} from '../../redux/reducers/generic/project';
import { useSelector } from 'react-redux';
import trackEvent from '../../helpers/trackEvent';

const menuItems = [
    // { id: 1, label: 'Leaderboard', href: '/leaderboard' },
    { id: 5, label: 'Apply as a Dev Agency', href: '/dev-agency/' },
    { id: 1, label: 'Developer Directory', href: '/directory' },
    { id: 2, label: 'How It Works', href: '/how-it-works' },
    // { id: 3, label: 'pre.dev vs Competitors', href: '/competitors' },
    { id: 3, label: 'Partners', href: '/partners' },
    { id: 4, label: 'Pricing', href: '/pricing' },
    { id: 6, label: 'Contact Us', href: talkToPreDev }
];

const Logo = () => (
    <a href="/" className="flex items-center lg:pl-10">
        <img
            src="/predev.png"
            width={34}
            height={24}
            className="h-6 w-[34px]"
            alt="Company Logo"
        />
        <span className="ml-2 self-center text-lg whitespace-nowrap text-white">
            pre.dev
        </span>
    </a>
);

const DashboardDropdown = ({ hasEnterprise, navigate }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className="relative"
            onMouseEnter={() => hasEnterprise && setIsOpen(true)}
        >
            <button
                onClick={() =>
                    hasEnterprise ? setIsOpen(!isOpen) : navigate('/projects')
                }
                className="cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-gray-800 text-white shadow-sm hover:bg-gray-700 h-9 px-4 py-2 mr-6 text-sm gap-2"
            >
                Dashboard
                {hasEnterprise && <ChevronDown className="h-4 w-4" />}
            </button>

            {hasEnterprise && isOpen && (
                <div
                    onMouseEnter={() => setIsOpen(true)}
                    onMouseLeave={() => setIsOpen(false)}
                    className="absolute top-full left-0 mt-2 gap-y-2 w-[180px] bg-gray-800 rounded-md shadow-lg overflow-hidden"
                >
                    <button
                        onClick={() => navigate('/projects')}
                        className="w-full text-left px-4 py-2 text-blue-400 text-sm hover:bg-gray-600"
                    >
                        Solo Dashboard
                    </button>
                    <button
                        onClick={() => navigate('/enterprise/dashboard')}
                        className="w-full text-left px-4 py-2 text-green-400 text-sm hover:bg-gray-600"
                    >
                        Enterprise Dashboard
                    </button>
                </div>
            )}
        </div>
    );
};

const LoginSection = ({
    onGetStarted,
    sessionContext,
    hasEnterprise,
    navigate
}) => {
    return sessionContext?.doesSessionExist ? (
        <DashboardDropdown hasEnterprise={hasEnterprise} navigate={navigate} />
    ) : (
        <div className="flex items-center gap-x-8">
            <a
                onClick={() => {
                    onGetStarted('signin');
                }}
                className="hidden sm:block cursor-pointer text-white text-sm"
            >
                Log in
            </a>
            <a
                onClick={() => {
                    onGetStarted('signup');
                }}
                className="cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-gray-800 text-white shadow-sm hover:bg-gray-700 h-9 px-4 py-2 mr-6 text-sm"
            >
                Sign up
            </a>
        </div>
    );
};

const HamburgerButton = ({ isOpen, onClick }) => (
    <button className="text-white" onClick={onClick}>
        <span className="sr-only">Toggle menu</span>
        {isOpen ? <XIcon className="text-white" /> : <AlignJustify />}
    </button>
);

const MobileNav = ({
    isOpen,
    onClose,
    onGetStarted,
    hasEnterprise,
    navigate,
    sessionContext
}) => {
    const mobileMenuItems = [...menuItems];

    if (sessionContext?.doesSessionExist) {
        if (hasEnterprise) {
            mobileMenuItems.unshift(
                {
                    id: 'enterprise',
                    label: 'Enterprise Dashboard',
                    href: '/enterprise/dashboard',
                    isDashboard: true
                },
                {
                    id: 'solo',
                    label: 'Solo Dashboard',
                    href: '/projects',
                    isDashboard: true
                }
            );
        } else {
            mobileMenuItems.unshift({
                id: 'dashboard',
                label: 'Dashboard',
                href: '/projects',
                isDashboard: true
            });
        }
    }

    const handleDashboardClick = href => {
        navigate(href);
        onClose();
    };

    return (
        <AnimatePresence>
            <motion.nav
                initial="initial"
                exit="exit"
                variants={{
                    initial: { opacity: 0, scale: 1 },
                    animate: {
                        scale: 1,
                        opacity: 1,
                        transition: { duration: 0.2, ease: 'easeOut' }
                    },
                    exit: {
                        opacity: 0,
                        transition: {
                            duration: 0.2,
                            delay: 0.2,
                            ease: 'easeOut'
                        }
                    }
                }}
                animate={isOpen ? 'animate' : 'exit'}
                className={cn(
                    'fixed left-0 top-0 z-50 h-screen w-full overflow-auto bg-background/70 bg-black backdrop-blur-[12px]',
                    {
                        'pointer-events-none': !isOpen
                    }
                )}
            >
                <div className="container flex h-[3.5rem] items-center justify-between">
                    <a className="text-md flex items-center" href="/"></a>
                    <HamburgerButton isOpen={isOpen} onClick={onClose} />
                </div>
                <motion.ul
                    className="flex flex-col uppercase"
                    variants={{
                        open: { transition: { staggerChildren: 0.06 } }
                    }}
                    initial="initial"
                    animate={isOpen ? 'open' : 'exit'}
                >
                    {mobileMenuItems.map(item => (
                        <motion.li
                            key={item.id}
                            className="cursor-pointer border-grey-dark pl-6 py-0.5 border-b md:border-none"
                            variants={{
                                initial: { y: '-20px', opacity: 0 },
                                open: {
                                    y: 0,
                                    opacity: 1,
                                    transition: {
                                        duration: 0.3,
                                        ease: 'easeOut'
                                    }
                                }
                            }}
                        >
                            <a
                                className="text-white hover:text-grey flex h-[var(--navigation-height)] w-full items-center text-xl transition-[color,transform] duration-300"
                                href={item.href}
                                onClick={
                                    item.isDashboard
                                        ? () => handleDashboardClick(item.href)
                                        : onClose
                                }
                            >
                                {item.label}
                            </a>
                        </motion.li>
                    ))}
                </motion.ul>
            </motion.nav>
        </AnimatePresence>
    );
};
export function SiteHeader() {
    const { enterpriseOrganizations } = useEnterpriseOrganizations();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const sessionContext = useSessionContext();
    const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = useState(false);

    const hasEnterprise = enterpriseOrganizations.length > 0;

    const onGetStarted = mode => {
        let redirectURL = '/projects';
        if (hasEnterprise) {
            redirectURL = '/enterprise/dashboard';
        }
        setRedirectURL(redirectURL);
        if (sessionContext.doesSessionExist === true) {
            navigate(redirectURL);
        } else {
            navigate(`/auth/?mode=${mode}&redirectToPath=%2Fprojects`);
        }
    };

    useEffect(() => {
        const html = document.querySelector('html');
        if (html) html.classList.toggle('overflow-hidden', hamburgerMenuIsOpen);
    }, [hamburgerMenuIsOpen]);

    useEffect(() => {
        const closeHamburgerNavigation = () => setHamburgerMenuIsOpen(false);
        window.addEventListener('orientationchange', closeHamburgerNavigation);
        window.addEventListener('resize', closeHamburgerNavigation);

        return () => {
            window.removeEventListener(
                'orientationchange',
                closeHamburgerNavigation
            );
            window.removeEventListener('resize', closeHamburgerNavigation);
        };
    }, []);

    const requestModalOpen = useSelector(selectShowRequestModal);
    const budgetModalOpen = useSelector(selectShowBudgetModal);
    const location = useLocation();
    const isDevAgencyPage =
        location.pathname === '/dev-agency' ||
        location.pathname === '/dev-agency/';
    const isHomePage = location.pathname === '/' || location.pathname === '';
    const isDirectoryPage = location.pathname === '/directory';

    return (
        <div className="max-w-screen-xl mx-auto">
            <header
                className={`bg-black fixed left-0 top-0 ${
                    !requestModalOpen && !budgetModalOpen ? 'z-10' : ''
                } w-full border-b border-gray-800 backdrop-blur-[12px]`}
            >
                <div className="container flex h-[3.5rem] items-center justify-between">
                    <div className="flex items-center gap-x-6">
                        <Logo />
                        <div className="hidden md:flex gap-x-3">
                            <Link
                                to="/"
                                onClick={() => {
                                    trackEvent('navFoundersClicked');
                                }}
                                className={`cursor-pointer text-sm transition-colors duration-200 hover:text-blue-400 mr-4 ${
                                    isHomePage
                                        ? 'text-white font-medium'
                                        : 'text-gray-400'
                                }`}
                            >
                                For Founders
                            </Link>

                            <a
                                href="/dev-agency"
                                onClick={() => {
                                    trackEvent('navDevAgenciesClicked');
                                }}
                                className={`cursor-pointer text-sm transition-colors duration-200 hover:text-blue-400 mr-4 ${
                                    isDevAgencyPage
                                        ? 'text-white font-medium'
                                        : 'text-gray-400'
                                }`}
                            >
                                For Dev Agencies
                            </a>

                            <Link
                                to="/directory"
                                onClick={() => {
                                    trackEvent('navDirectoryClicked');
                                }}
                                className={`cursor-pointer text-sm transition-colors duration-200 hover:text-blue-400 mr-4 ${
                                    isDirectoryPage
                                        ? 'text-white font-medium'
                                        : 'text-gray-400'
                                }`}
                            >
                                Developer Directory
                            </Link>
                        </div>
                    </div>

                    <div className="flex items-center ml-auto lg:gap-x-2">
                        <LoginSection
                            onGetStarted={onGetStarted}
                            sessionContext={sessionContext}
                            hasEnterprise={hasEnterprise}
                            navigate={navigate}
                        />
                        <HamburgerButton
                            isOpen={hamburgerMenuIsOpen}
                            onClick={() =>
                                setHamburgerMenuIsOpen(open => !open)
                            }
                        />
                    </div>
                </div>
            </header>
            <MobileNav
                isOpen={hamburgerMenuIsOpen}
                onClose={() => setHamburgerMenuIsOpen(false)}
                onGetStarted={onGetStarted}
                hasEnterprise={hasEnterprise}
                navigate={navigate}
                sessionContext={sessionContext}
            />
        </div>
    );
}

export default SiteHeader;
