import { GET_PROJECTS_BY_ENTERPRISE_ORGANIZATION } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchProjectsRequest,
    fetchProjectsSuccess,
    fetchProjectsFailure,
    fetchPaginatedProjects
} from '../../reducers/generic/projects';
import { projectsLimit } from '../../../constants';

// Action Creators
export const getProjectsByEnterpriseOrganization =
    ({
        enterpriseOrganizationId,
        skip = 0,
        limit = projectsLimit,
        searchQuery,
        creationMethodFilter,
        sort,
        matchWithDevsOnly,
        ignorePreDevEmails
    }) =>
    async (dispatch, getState) => {
        if (skip === 0) dispatch(fetchProjectsRequest());
        try {
            const { data } = await client.query({
                query: GET_PROJECTS_BY_ENTERPRISE_ORGANIZATION,
                variables: {
                    enterpriseOrganizationId,
                    skip,
                    limit,
                    searchQuery,
                    creationMethodFilter,
                    sort,
                    matchWithDevsOnly,
                    ignorePreDevEmails
                },
                fetchPolicy: 'network-only'
            });
            if (skip > 0)
                dispatch(
                    fetchPaginatedProjects(
                        data.projectsByEnterpriseOrganization.projects
                    )
                );
            else
                dispatch(
                    fetchProjectsSuccess(data.projectsByEnterpriseOrganization)
                );
        } catch (error) {
            console.log({ error });
            dispatch(fetchProjectsFailure(error));
        }
    };
