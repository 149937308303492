'use client';
import {
    useMotionValueEvent,
    useScroll,
    useTransform,
    motion
} from 'framer-motion';
import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';

interface TimelineEntry {
    title: string;
    savings?: {
        human: string;
        predev: string;
        text: string;
        optimiztion?: string;
    };
    content: React.ReactNode;
}

export const Timeline = ({
    data,
    noHeader,
    extraContent
}: {
    data: TimelineEntry[];
    noHeader?: boolean;
    extraContent?: React.ReactNode;
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [height, setHeight] = useState(0);
    const [inView, setInView] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setInView(entry.isIntersecting);
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref]);

    useLayoutEffect(() => {
        if (inView && ref.current) {
            const rect = ref.current.getBoundingClientRect();
            setHeight(rect.height);
        }
    }, [inView, ref, data]);

    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ['start 10%', 'end 50%']
    });

    useEffect(() => {
        if (inView) setInitialLoad(true);
    }, [inView]);
    // Assuming 768px as the breakpoint for small screens
    const heightTransform = useTransform(scrollYProgress, [0, 1], [0, height]);
    const opacityTransform = useTransform(scrollYProgress, [0, 0.1], [0, 1]);

    const savingsDiv = (item: TimelineEntry) => {
        return (
            <>
                {item.savings && (
                    <div className="mt-4 mb-6 bg-gradient-to-r from-blue-950/40 to-blue-800/30 rounded-lg border border-blue-600/40 p-2 shadow-lg">
                        <div className="text-lg font-semibold text-blue-200 mb-2">
                            {item.savings.text} Comparison
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2">
                            <div className="flex-1 bg-blue-950/50 rounded-md p-2 border border-blue-700/30">
                                <div className="text-blue-300 text-sm">
                                    Traditional
                                </div>
                                <div className="text-white text-lg font-bold">
                                    {item.savings.human}
                                </div>
                            </div>
                            <div className="flex-1 bg-blue-950/50 rounded-md p-2 border border-blue-500/40">
                                <div className="text-blue-200 text-sm">
                                    With pre.dev
                                </div>
                                <div className="text-white text-lg font-bold">
                                    {item.savings.predev}
                                </div>
                            </div>
                        </div>
                        {item.savings.optimiztion && (
                            <div className="mt-3 text-center bg-gradient-to-r from-yellow-700/40 to-yellow-500/30 rounded-lg py-2 px-3 border-2 border-yellow-500/50 shadow-lg transform hover:scale-105 transition-all duration-300">
                                <div className="flex items-center justify-center gap-2">
                                    <span
                                        role="img"
                                        aria-label="rocket"
                                        className="text-xl animate-pulse"
                                    >
                                        🚀
                                    </span>
                                    <span className="text-transparent text-xl bg-clip-text bg-gradient-to-r from-yellow-200 to-yellow-400 font-bold">
                                        {item.savings.optimiztion}
                                    </span>
                                    <span
                                        role="img"
                                        aria-label="rocket"
                                        className="text-xl animate-pulse"
                                    >
                                        🚀
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="mt-3 text-center text-sm">
                            <button
                                onClick={() =>
                                    window.open(
                                        'https://calendly.com/pre_dev/talk-to-sales',
                                        '_blank'
                                    )
                                }
                                className="bg-gradient-to-r from-blue-600 to-blue-800 hover:from-blue-700 hover:to-blue-900 text-white font-medium py-2 px-4 rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300 flex items-center justify-center mx-auto"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 mr-2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                                    />
                                </svg>
                                Book a Demo
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        <div
            className="w-full bg-white dark:bg-neutral-950 font-sans md:px-10"
            ref={containerRef}
        >
            {!noHeader && (
                <div className="max-w-7xl mx-auto pt-20 px-4 md:px-8 lg:px-10">
                    <h2 className="text-3xl md:text-5xl mb-4 text-black dark:text-white max-w-4xl">
                        Your <span className="text-blue-600">pre.dev</span>{' '}
                        Journey to MVP in 3 Steps
                    </h2>
                    <p className="text-neutral-700 dark:text-neutral-300 text-base md:text-base max-w-xl">
                        Let us show you how pre.dev AI builds your custom
                        prototype and matches you with a team of professional
                        developers aligned to your exact product specifications
                        and budget.
                    </p>
                </div>
            )}
            {extraContent && (
                <div className="max-w-7xl mx-auto pt-20 px-4 md:px-8 lg:px-10">
                    {extraContent}
                </div>
            )}

            <div ref={ref} className="relative max-w-7xl mx-auto pb-20">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className="flex justify-start pt-10 md:pt-20 md:gap-10"
                    >
                        <div
                            className="sticky flex flex-col md:flex-row items-center top-40 self-start max-w-xs lg:max-w-sm md:w-full"
                            style={{ zIndex: 5 }}
                        >
                            <div className="h-10 absolute left-3 md:left-3 w-10 rounded-full bg-white dark:bg-black flex items-center justify-center">
                                <div className="h-4 w-4 rounded-full bg-neutral-200 dark:bg-neutral-800 border border-neutral-300 dark:border-neutral-700 p-2" />
                            </div>
                            <h3 className="hidden md:block text-xl md:pl-20 md:text-5xl font-bold text-neutral-300 dark:text-neutral-300 ">
                                {item.title}
                                {savingsDiv(item)}
                            </h3>
                        </div>

                        <div className="relative pl-14 pr-4 md:pl-4 w-full">
                            <h3 className="md:hidden block text-2xl mb-4 text-left font-bold text-neutral-300 dark:text-neutral-300">
                                {item.title}
                            </h3>
                            <div className="md:hidden">{savingsDiv(item)}</div>
                            <div className="w-full">{item.content} </div>
                        </div>
                    </div>
                ))}
                <div
                    style={{
                        height: height + 'px'
                    }}
                    className="absolute md:left-8 left-8 top-0 overflow-hidden w-[2px] bg-[linear-gradient(to_bottom,var(--tw-gradient-stops))] from-transparent from-[0%] via-neutral-200 dark:via-neutral-700 to-transparent to-[99%]  [mask-image:linear-gradient(to_bottom,transparent_0%,black_10%,black_90%,transparent_100%)] "
                >
                    <motion.div
                        style={{
                            height: heightTransform,
                            opacity: opacityTransform
                        }}
                        className="absolute inset-x-0 top-0  w-[2px] bg-gradient-to-t from-purple-500 via-blue-500 to-transparent from-[0%] via-[10%] rounded-full"
                    />
                </div>
            </div>
        </div>
    );
};
