import { useDispatch } from 'react-redux';
import { client } from '../../../../../api/client';
import {
    INITIATE_SUGGESTION_WITH_INPUT,
    SUGGESTION_SUBSCRIPTION
} from '../../../../../api/graphQL';
import { waitingForSuggestion } from '../../../../../redux/reducers/generic/chatHistory';
import { Spinner } from '../../../../Components/Spinner';
import { useSubscription } from '@apollo/client';
import { useProject } from '../../../../../context-providers/Project';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { toast } from 'sonner';
import { SparklesIcon } from '@heroicons/react/24/solid';
import trackEvent from '../../../../../helpers/trackEvent';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import * as Tooltip from '@radix-ui/react-tooltip';

export const SuggestWithInput = ({
    answer,
    setAnswer,
    enhancing,
    setEnhancing
}) => {
    let { chatHistory, projectId, project } = useProject();
    let waiting =
        chatHistory.waitingForChatResponse || chatHistory.waitingForSuggestion;

    let [randId, setRandId] = useState('');
    const [isSuggesting, setIsSuggesting] = useState(false);

    const dispatch = useDispatch();
    const { accessTokenPayload } = useSessionContext();

    useSubscription(SUGGESTION_SUBSCRIPTION, {
        variables: { projectId, randId },
        shouldResubscribe: true,
        onData: data => {
            try {
                const { suggestion, completed } =
                    data.data.data.suggestionStream;

                if (completed) {
                    dispatch(waitingForSuggestion(false));
                    setIsSuggesting(false);
                    setAnswer(suggestion.trim());
                    setEnhancing(false);
                } else {
                    dispatch(waitingForSuggestion(true));
                    setAnswer(suggestion.trim());
                    setIsSuggesting(true);
                    setEnhancing(false);
                }
            } catch (error) {
                console.log(error);
                dispatch(waitingForSuggestion(false));
                setIsSuggesting(false);
                setEnhancing(false);
            }
        },
        onError: error => {
            console.log(JSON.stringify(error, null, 2));
            dispatch(waitingForSuggestion(false));
            setIsSuggesting(false);
        }
    });

    const handleSuggestWithInput = async () => {
        if (waiting || isSuggesting) return;

        if (!answer.trim()) {
            toast.error('Please enter something to get a suggestion');
            return;
        }

        if (enhancing) return;

        setEnhancing(true);
        setIsSuggesting(true);
        dispatch(waitingForSuggestion(true));

        try {
            const userInput = answer;
            let randId = uuid();
            setRandId(randId);

            trackEvent('suggestWithInput', {
                userEmail: project?.userEmail,
                userId: accessTokenPayload?.userId
            });

            let data = await client.mutate({
                mutation: INITIATE_SUGGESTION_WITH_INPUT,
                variables: { projectId, randId, userInput }
            });

            let suggestion =
                data.data.initiateSuggestionWithInputStream.suggestion;
            setAnswer(suggestion.trim());
            dispatch(waitingForSuggestion(false));
            setIsSuggesting(false);
        } catch (error) {
            console.log(error);
            dispatch(waitingForSuggestion(false));
            setIsSuggesting(false);
            toast.error('Failed to generate suggestion');
        } finally {
            setEnhancing(false);
        }
    };

    return (
        answer && (
            <Tooltip.Provider delayDuration={0}>
                <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                        <button
                            onClick={
                                !waiting && !isSuggesting
                                    ? handleSuggestWithInput
                                    : null
                            }
                            className={`p-2 rounded-md ${
                                waiting || isSuggesting
                                    ? 'text-gray-400 cursor-not-allowed'
                                    : 'text-[var(--widget-primary-color,#4F46E5)] hover:text-[var(--widget-primary-color,#4F46E5)]/80 hover:bg-[var(--widget-primary-color,#4F46E5)]/10'
                            } transition duration-200`}
                            disabled={waiting || isSuggesting}
                            type="button"
                        >
                            {isSuggesting ? (
                                <Spinner w="4" h="4" />
                            ) : (
                                <SparklesIcon className="w-5 h-5" />
                            )}
                        </button>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                        <Tooltip.Content
                            className="bg-[var(--widget-background-color,#FFFFFF)] text-[var(--widget-text-color,#111827)] border border-gray-200 px-3 py-1.5 rounded-md text-sm shadow-md"
                            sideOffset={5}
                        >
                            Enhance your answer with AI
                            <Tooltip.Arrow className="fill-[var(--widget-background-color,#FFFFFF)]" />
                        </Tooltip.Content>
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider>
        )
    );
};
