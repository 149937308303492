// 3 main pages of flow
import Chat from './Chat';
import MainHeader from './MainHeader';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    clearWidgetState,
    setAPIKey
} from '../../../../redux/reducers/enterprise_widget/enterpriseWidget';
import { useSelector } from 'react-redux';
import { selectWidgetState } from '../../../../redux/reducers/enterprise_widget/enterpriseWidget';
import CreateNewProject from '../../../CreateNewProject';
import ProjectCompleted from './ProjectCompleted';
import { useProject } from '../../../../context-providers/Project';
import { useEffect } from 'react';
import { selectEnterpriseOrganization } from '../../../../redux/reducers/enterprise/enterpriseOrganization';
import ContentLoader from 'react-content-loader';

// Widget skeleton component for loading state
const WidgetSkeleton = ({ speed = 2 }) => (
    <div className="h-[590px] border-gray-200 border rounded-lg shadow-lg object-cover mx-auto max-w-[420px] bg-white flex-grow relative overflow-hidden">
        {/* Shimmer overlay */}
        <div className="absolute inset-0 -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent via-gray-200/20 to-transparent"></div>

        <ContentLoader
            speed={speed}
            width="100%"
            height="93%"
            viewBox="0 0 440 590"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            preserveAspectRatio="xMidYMid meet"
        >
            {/* Header */}
            <rect x="60" y="10" rx="0" ry="0" width="300" height="60" />

            {/* Content placeholders */}
            <rect x="40" y="120" rx="4" ry="4" width="360" height="50" />
            <rect x="40" y="180" rx="4" ry="4" width="360" height="50" />
            <rect x="40" y="240" rx="4" ry="4" width="360" height="50" />
            <rect x="40" y="300" rx="4" ry="4" width="360" height="100" />
            <rect x="40" y="420" rx="4" ry="4" width="360" height="50" />

            {/* Input field placeholder */}

            {/* Button placeholder */}
            <rect x="120" y="520" width="200" height="50" />
        </ContentLoader>
        <MainHeader />
    </div>
);

const Widget = ({ apiKeySub }) => {
    let { apiKey } = useParams();
    const dispatch = useDispatch();
    dispatch(setAPIKey(apiKeySub || apiKey));
    const widgetState = useSelector(selectWidgetState);
    const { project } = useProject();
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);

    useEffect(() => {
        // Clear widget state when component unmounts
        return () => {
            dispatch(clearWidgetState());
        };
    }, []);

    // Show loading skeleton when enterpriseOrganization is not loaded yet
    if (!enterpriseOrganization) {
        return <WidgetSkeleton />;
    }

    // Default colors if not set in enterprise organization
    const primaryColor = enterpriseOrganization?.primaryColor || '#111827'; // Default even light blue
    const secondaryColor = enterpriseOrganization?.secondaryColor || '#10B981'; // Default emerald
    const textColor = enterpriseOrganization?.textColor || '#111827'; // Default gray-900
    const backgroundColor =
        enterpriseOrganization?.backgroundColor || '#FFFFFF'; // Default white

    return (
        <div
            style={{
                '--widget-primary-color': primaryColor,
                '--widget-secondary-color': secondaryColor,
                '--widget-text-color': textColor,
                '--widget-background-color': backgroundColor
            }}
        >
            <section
                className={`h-[590px] border-gray-200 border rounded-lg shadow-lg object-cover mx-auto max-w-[420px] bg-[var(--widget-background-color,#FFFFFF)] text-[var(--widget-text-color,#111827)] flex-grow flex flex-col justify-between`}
            >
                {enterpriseOrganization?.widgetManagementActivated ||
                window.location.hostname === 'localhost' ||
                window.location.hostname === 'pre.dev' ? (
                    <>
                        {widgetState === 'initial' && (
                            <CreateNewProject isWidget={true} />
                        )}
                        {widgetState === 'chat' && project && <Chat />}
                        {widgetState === 'chat' && !project && <div></div>}
                        {widgetState === 'projectCompleted' && (
                            <ProjectCompleted />
                        )}
                        <MainHeader />
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center h-full space-y-6 p-8">
                        <img
                            src="/predevblack.png"
                            alt="Pre.dev Logo"
                            className="h-24"
                        />
                        <p className="text-center text-2xl font-semibold">
                            Scoping Widget is not available for this
                            organization
                        </p>
                        <p className="text-center text-md text-gray-600">
                            Please contact your administrator for more
                            information.
                        </p>
                    </div>
                )}
            </section>
        </div>
    );
};

export default Widget;
