import { UserAuthForm } from '../../Auth/user-auth-form';
import Modal from '../../Components/Modal';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectUserAuthModalOpen,
    setUserAuthModalOpen
} from '../../../redux/reducers/generic/landing';

export function LoginToContinue() {
    const userAuthModalOpen = useSelector(selectUserAuthModalOpen);
    const dispatch = useDispatch();
    return (
        <Modal
            open={userAuthModalOpen}
            maxWidth="max-w-2xl py-8 sm:mt-0"
            bgColor="bg-black"
            hideOuter={true}
            onClose={() => {
                dispatch(setUserAuthModalOpen(false));
            }}
        >
            <div className="flex flex-col items-center space-y-6 p-2">
                <h2 className="text-2xl font-extrabold">
                    <span className="mr-2">🚀</span>
                    <span className="text-white">Sign Up to Continue</span>
                </h2>
                <UserAuthForm />
            </div>
        </Modal>
    );
}
