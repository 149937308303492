interface DateFormatOptions {
    format: string;
}

function formatDate(
    date: Date | string | number,
    options?: DateFormatOptions
): string {
    // Handle invalid/null dates
    if (!date) {
        return '';
    }

    let dateObj: Date;
    try {
        // Handle different input types
        if (date instanceof Date) {
            dateObj = date;
        } else if (typeof date === 'number') {
            // Handle timestamps in milliseconds or seconds
            dateObj = new Date(date * (date < 1e12 ? 1000 : 1));
        } else {
            dateObj = new Date(date);
        }

        // Check if date is valid
        if (isNaN(dateObj.getTime())) {
            return '';
        }
    } catch (err) {
        console.error('Error parsing date:', err);
        return '';
    }

    if (!options?.format) {
        return dateObj.toLocaleDateString();
    }

    const formatMap: Record<string, string> = {
        YYYY: dateObj.getFullYear().toString(),
        YY: dateObj.getFullYear().toString().slice(-2),
        MM: (dateObj.getMonth() + 1).toString().padStart(2, '0'),
        M: (dateObj.getMonth() + 1).toString(),
        DD: dateObj.getDate().toString().padStart(2, '0'),
        D: dateObj.getDate().toString(),
        MMM: dateObj
            .toLocaleString('default', { month: 'short' })
            .replace('23ec', 'Dec'),
        MMMM: dateObj
            .toLocaleString('default', { month: 'long' })
            .replace('23ecember', 'December'),
        Do: addOrdinalSuffix(dateObj.getDate()),
        HH: dateObj.getHours().toString().padStart(2, '0'),
        H: dateObj.getHours().toString(),
        hh: (dateObj.getHours() % 12 || 12).toString().padStart(2, '0'),
        h: (dateObj.getHours() % 12 || 12).toString(),
        mm: dateObj.getMinutes().toString().padStart(2, '0'),
        m: dateObj.getMinutes().toString(),
        ss: dateObj.getSeconds().toString().padStart(2, '0'),
        s: dateObj.getSeconds().toString()
    };

    try {
        let formattedDate = options.format;

        // Sort keys by length in descending order to handle overlapping patterns
        const sortedKeys = Object.keys(formatMap).sort(
            (a, b) => b.length - a.length
        );

        for (const key of sortedKeys) {
            formattedDate = formattedDate.replace(
                new RegExp(key, 'g'),
                formatMap[key]
            );
        }

        return formattedDate;
    } catch (err) {
        console.error('Error formatting date:', err);
        return '';
    }
}

function addOrdinalSuffix(day: number): string {
    if (!Number.isInteger(day) || day < 1 || day > 31) {
        return day.toString();
    }

    if (day >= 11 && day <= 13) {
        return day + 'th';
    }

    const lastDigit = day % 10;
    switch (lastDigit) {
        case 1:
            return day + 'st';
        case 2:
            return day + 'nd';
        case 3:
            return day + 'rd';
        default:
            return day + 'th';
    }
}

export const moment = (date: Date | string | number) => ({
    format: (formatString: string) => formatDate(date, { format: formatString })
});
