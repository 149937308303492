import { client } from '../../../api/client';
import { UPDATE_PROJECT_WITH_EMAIL } from '../../../api/graphQL/solo/mutator';

export const updateProjectWithEmail =
    (
        projectId: string,
        userEmail: string,
        utm_source?: string,
        utm_medium?: string,
        utm_campaign?: string,
        utm_url?: string
    ) =>
    async (dispatch, getState) => {
        try {
            const { data } = await client.mutate({
                mutation: UPDATE_PROJECT_WITH_EMAIL,
                variables: {
                    projectId,
                    userEmail,
                    utm_source,
                    utm_medium,
                    utm_campaign,
                    utm_url
                }
            });

            return data.updateProjectWithEmail;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }
    };
