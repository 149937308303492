import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    billingModalOpen: false,
    subscribeModalOpen: false,
    subscribeBannerOpen: false,
    discountOfferModalOpen: false
};

export const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        toggleBillingModalOpen: (state, action) => {
            let override = action.payload !== undefined;
            state.billingModalOpen = override
                ? action.payload
                : !state.billingModalOpen;
        },
        toggleSubscribeModal: (state, action) => {
            let override = action.payload !== undefined;

            state.subscribeModalOpen = override
                ? action.payload
                : !state.subscribeModalOpen;
        },
        toggleSubscribeBanner: (state, action) => {
            let override = action.payload !== undefined;
            state.subscribeBannerOpen = override
                ? action.payload
                : !state.subscribeBannerOpen;
        },
        toggleDiscountOfferModal: (state, action) => {
            let override = action.payload !== undefined;
            state.discountOfferModalOpen = override
                ? action.payload
                : !state.discountOfferModalOpen;
        }
    }
});
export const {
    toggleBillingModalOpen,
    toggleSubscribeModal,
    toggleSubscribeBanner,
    toggleDiscountOfferModal
} = billingSlice.actions;
export const selectBillingModalOpen = state => state.billing.billingModalOpen;
export const selectSubscribeModal = state => state.billing.subscribeModalOpen;
export const selectSubscribeBanner = state => state.billing.subscribeBannerOpen;
export const selectDiscountOfferModal = state =>
    state.billing.discountOfferModalOpen;

export default billingSlice.reducer;
