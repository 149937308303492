import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useProject } from '../../../../../context-providers/Project';
import { skipQuestions } from '../../../../../redux/actions/enterprise_widget/skipQuestions';
import { setWidgetState } from '../../../../../redux/reducers/enterprise_widget/enterpriseWidget';
import { ForwardIcon } from '@heroicons/react/24/outline';
import * as Tooltip from '@radix-ui/react-tooltip';

export const SkipQuestions = ({ isWidget }) => {
    let { chatHistory, projectId, project } = useProject();

    const dispatch = useDispatch();
    const [isSkipping, setIsSkipping] = useState(false);

    const handleSkipQuestions = async () => {
        setIsSkipping(true);
        await dispatch(skipQuestions(projectId));
        if (isWidget) {
            dispatch(setWidgetState('projectCompleted'));
        }
    };

    return (
        <Tooltip.Provider delayDuration={0}>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <button
                        onClick={handleSkipQuestions}
                        className={`flex items-center justify-center p-2 rounded-md ${
                            isSkipping
                                ? 'text-gray-400 cursor-not-allowed'
                                : 'text-[var(--widget-primary-color,#4F46E5)] hover:text-[var(--widget-primary-color,#4F46E5)]/80 hover:bg-[var(--widget-primary-color,#4F46E5)]/10'
                        } transition duration-200`}
                        disabled={isSkipping}
                    >
                        {isSkipping ? (
                            <span className="text-xs">Skipping...</span>
                        ) : (
                            <ForwardIcon className="w-5 h-5" />
                        )}
                    </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        className="bg-[var(--widget-background-color,#FFFFFF)] text-[var(--widget-text-color,#111827)] border border-gray-200 px-3 py-1.5 rounded-md text-sm shadow-md"
                        sideOffset={5}
                    >
                        Skip the initial questions
                        <Tooltip.Arrow className="fill-[var(--widget-background-color,#FFFFFF)]" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.Provider>
    );
};
