import React, { useState, useEffect } from 'react';
import { useProject } from '../../../../context-providers/Project';
import { SkipQuestions } from './ChatInput/SkipQuestions';

export const Progress = () => {
    let { chatHistory } = useProject();
    let chat = chatHistory.data;

    // simple progress calculation we are just going to keep at local state for ease
    const [progress, setProgress] = useState(0); // Initial progress is 0%
    useEffect(() => {
        // Calculate progress percentage and update progress state
        if (chat && chat.length > 1) {
            let newProgress = Math.floor(chat.length / 2);
            setProgress(Math.min(newProgress, 5));
        } else {
            setProgress(0);
        }
    }, [chat]);

    return (
        <div className="w-full px-4 py-3">
            <div className="flex items-center gap-3">
                <span className="text-sm whitespace-nowrap text-[var(--widget-text-color,gray-500)]">
                    Responses {progress}/5
                </span>
                <div className="flex-1 bg-gray-200 rounded-full h-2 dark:bg-gray-700">
                    <div
                        className="bg-[var(--widget-primary-color,#4F46E5)] h-2 rounded-full transition-all duration-300"
                        style={{ width: `${(progress / 5) * 100}%` }}
                    ></div>
                </div>
                <SkipQuestions isWidget={true} />
            </div>
        </div>
    );
};
