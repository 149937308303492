import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SwitchDefault } from '../../WidgetManagement/Helpers/SwitchDefault';
import { useParams } from 'react-router-dom';
import { enableLeadGen } from '../../../../redux/actions/enterprise/enableLeadGen';
import { enablePremiumLeadGen } from '../../../../redux/actions/enterprise/enablePremiumLeadGen';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';

export const LeadGenToggle = ({ project, textColor = 'text-gray-700' }) => {
    const { enterpriseOrganizationId } = useParams();
    const [leadGenLoading, setLeadGenLoading] = useState(false);
    const [premiumLeadGenLoading, setPremiumLeadGenLoading] = useState(false);
    const dispatch = useDispatch();
    const { isLeadGen } = useSubscriptionPayment();

    const toggleLeadGen = async () => {
        setLeadGenLoading(true);
        await dispatch(
            enableLeadGen({
                projectId: project._id,
                value: !project.leadGenEnabled
            })
        );
        setLeadGenLoading(false);
    };

    const togglePremiumLeadGen = async () => {
        setPremiumLeadGenLoading(true);
        await dispatch(
            enablePremiumLeadGen({
                projectId: project._id,
                value: !project.premiumLeadGenEnabled
            })
        );
        setPremiumLeadGenLoading(false);
    };

    const showToggle =
        enterpriseOrganizationId ===
            process.env.REACT_APP_PREDEV_ORGANIZATION_ID &&
        project.matchWithDev;

    return (
        !isLeadGen &&
        showToggle && (
            <div className="flex items-center gap-x-4 mt-2">
                <div className="flex items-center gap-x-2">
                    <span className={`text-sm ${textColor}`}>Lead</span>
                    <SwitchDefault
                        setEnabled={toggleLeadGen}
                        enabled={project.leadGenEnabled}
                        loading={leadGenLoading}
                    />
                </div>
                {project.leadGenEnabled && (
                    <div className="flex items-center gap-x-2">
                        <span className={`text-sm ${textColor}`}>Premium</span>
                        <SwitchDefault
                            setEnabled={togglePremiumLeadGen}
                            enabled={project.premiumLeadGenEnabled}
                            loading={premiumLeadGenLoading}
                        />
                    </div>
                )}
            </div>
        )
    );
};
