import React, { createContext, useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getEnterpriseOrganizationByUser } from '../redux/actions/enterprise/getEnterpriseOrganizationByUser';
import {
    selectEnterpriseOrganization,
    setProfileCompletion
} from '../redux/reducers/enterprise/enterpriseOrganization';

import { selectApiDomains } from '../redux/reducers/enterprise/clientOnboarding';
import { selectEnterpriseUsers } from '../redux/reducers/enterprise/enterpriseUsers';

import { useParams } from 'react-router-dom';
import { selectAPIKey } from '../redux/reducers/enterprise_widget/enterpriseWidget';
import { getEnterpriseOrganizationByApiKey } from '../redux/actions/enterprise_widget/getEnterpriseOrganizationByApiKey';

const EnterpriseOrganizationContext = createContext();

export function EnterpriseOrganizationProvider({ children }) {
    const { doesSessionExist, accessTokenPayload } = useSessionContext();
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const apiKey = useSelector(selectAPIKey);

    const apiDomainsObj = useSelector(selectApiDomains);

    const enterpriseUsersObj = useSelector(selectEnterpriseUsers);
    // api domains
    const apiDomains = apiDomainsObj.data;
    const apiDomainsError = apiDomainsObj.error;
    const apiDomainsLoading = apiDomainsObj.loading;

    const enterpriseUsers = enterpriseUsersObj.data;
    const enterpriseUsersError = enterpriseUsersObj.error;
    const enterpriseUsersLoading = enterpriseUsersObj.loading;

    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();

    useEffect(() => {
        if (apiKey && !enterpriseOrganizationId)
            dispatch(getEnterpriseOrganizationByApiKey(apiKey));
    }, [apiKey, enterpriseOrganizationId]);

    //determine when to mount this data
    useEffect(() => {
        if (enterpriseOrganizationId) {
            dispatch(getEnterpriseOrganizationByUser(enterpriseOrganizationId));
        }
    }, [doesSessionExist, enterpriseOrganizationId]);

    const originalData = {
        introductionLogo: enterpriseOrganization?.introductionLogo || '',
        name: enterpriseOrganization?.name || '',
        description: enterpriseOrganization?.description || '',
        websiteURL: enterpriseOrganization?.websiteURL || '',
        address1: enterpriseOrganization?.address1 || '',
        address2: enterpriseOrganization?.address2 || '',
        city: enterpriseOrganization?.city || '',
        state: enterpriseOrganization?.state || '',
        zip: enterpriseOrganization?.zip || '',
        country: enterpriseOrganization?.country || {
            name: '',
            code: ''
        },
        phone: enterpriseOrganization?.phone || '',
        reviewPageLink: enterpriseOrganization?.reviewPageLink || '',
        linkedInLink: enterpriseOrganization?.linkedInLink || '',
        githubLink: enterpriseOrganization?.githubLink || '',
        calendlyLink: enterpriseOrganization?.calendlyLink || '',
        techStackItems: enterpriseOrganization?.techStackItems || [],
        industriesServed: enterpriseOrganization?.industriesServed || [],
        portfolioProjects: enterpriseOrganization?.portfolioProjects || [],
        isPhoneVerified: enterpriseOrganization?.isPhoneVerified || false
    };

    const calculateProfileCompletion = () => {
        const requiredFields = [
            'introductionLogo',
            'name',
            'description',
            'websiteURL',
            'phone',
            'address1',
            'city',
            'state',
            'country',
            'techStackItems',
            'industriesServed',
            'reviewPageLink',
            'calendlyLink'
        ];

        const completedFields = requiredFields.filter(field => {
            const value = originalData[field];
            return Array.isArray(value) ? value.length > 0 : Boolean(value);
        });

        const profileCompletion = {
            percentage: Math.round(
                (completedFields.length / requiredFields.length) * 100
            ),
            missingFields: requiredFields.filter(field => {
                const value = originalData[field];
                return !(Array.isArray(value)
                    ? value.length > 0
                    : Boolean(value));
            })
        };

        dispatch(setProfileCompletion(profileCompletion));
    };

    useEffect(() => {
        calculateProfileCompletion();
    }, [enterpriseOrganization]);

    return (
        <EnterpriseOrganizationContext.Provider
            value={{
                enterpriseOrganization,
                enterpriseOrganizationId,
                apiDomains,
                apiDomainsLoading,
                apiDomainsError,
                enterpriseUsers,
                enterpriseUsersError,
                enterpriseUsersLoading
            }}
        >
            {children}
        </EnterpriseOrganizationContext.Provider>
    );
}

export const useEnterpriseOrganization = () =>
    useContext(EnterpriseOrganizationContext);
