import { useState, useEffect, lazy, Suspense } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Progress } from '../../ProjectDashboard/ArchitectureGraph/Progress';
import { QuestionExperience } from './question_experience';
import TextShimmer from '../../Components/magicui/text-shimmer';
import { useDispatch } from 'react-redux';
import { setShowRequestModal } from '../../../redux/reducers/generic/project';
import RequestModal from '../../ProjectDashboard/RequestMeeting/Requestmodal';
import trackEvent from '../../../helpers/trackEvent';
import { Loading } from '../../Components/Loading';
import { AnimatePresence, motion } from 'framer-motion';
import { SubscribeModal } from '../../SoloSubscribeModal';
import { toast } from 'sonner';
import { LoginToContinue } from './LoginToContinue';
import CursorMasterPrompt from '../cursor-master-prompt';

const ProjectDashboard = lazy(() => import('../../ProjectDashboard'));

export const HeroExperience = () => {
    const [questionIndex, setQuestionIndex] = useState(0);
    const { projectId } = useParams();
    const [tempProjectId, setTempProjectId] = useState(null);
    const [isPreloading, setIsPreloading] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const dispatch = useDispatch();

    // Start preloading ProjectDashboard when tempProjectId is set
    useEffect(() => {
        if (tempProjectId && !isPreloading) {
            setIsPreloading(true);
            // Preload the ProjectDashboard component
            import('../../ProjectDashboard').catch(err => {
                console.error('Failed to preload ProjectDashboard:', err);
            });
        }
    }, [tempProjectId, isPreloading]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Check if scrolled to top and focus input after slight delay
        setTimeout(() => {
            if (window.scrollY === 0) {
                const mainInput = document.getElementById('mainInput');
                if (mainInput) {
                    // mainInput.focus();
                }
            }
        }, 2000);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {!projectId && !isMobile && (
                <div className="pt-16 absolute top-[10px] left-0 ml-2">
                    <Progress chatLength={questionIndex} total={2} />
                </div>
            )}
            <div className="hero-section">
                {!projectId && (
                    <div className="flex flex-col gap-y-3 absolute top-[65px] justify-center w-full mb-4">
                        <Link
                            to="/directory"
                            onClick={e => {
                                try {
                                    trackEvent('landingMatchedWithDev');
                                } catch (error) {
                                    console.log({ error });
                                }
                            }}
                            className="backdrop-filter-[12px] inline-flex py-2 items-center justify-between rounded-full border border-gray-500 bg-blue-500/20 px-3 text-xs transition-all ease-in hover:cursor-pointer hover:bg-blue-500/30 group gap-1 mx-auto"
                        >
                            <TextShimmer className="inline-flex items-center justify-center text-white text-md sm:text-md">
                                ✨ Match Me with Developers Now!
                            </TextShimmer>
                        </Link>
                    </div>
                )}
                <main className="max-w-full xl:px-16 min-w-0 flex-auto lg:static lg:max-h-full lg:overflow-visible">
                    <section
                        className="mb-8 pb-16 mx-auto flex sm:pb-10 lg:pb-24"
                        style={{
                            marginTop: projectId ? '0px' : '70px'
                        }}
                    >
                        {!projectId ? (
                            <section className="pb-16 mx-auto flex max-w-8xl flex-col overflow-hidden lg:px-20 lg:pb-24">
                                <div className="px-4 pt-20 mb-20">
                                    <QuestionExperience
                                        questionIndex={questionIndex}
                                        tempProjectId={tempProjectId}
                                        setTempProjectId={setTempProjectId}
                                        setQuestionIndex={setQuestionIndex}
                                    />
                                    {/* {questionIndex === 0 && (
                                        <div className="flex justify-center mt-4">
                                            <CursorMasterPrompt />
                                        </div>
                                    )} */}
                                </div>
                            </section>
                        ) : (
                            <div
                                className="w-full flex flex-col"
                                style={{
                                    height: isMobile
                                        ? 'calc(100vh - 135px)'
                                        : 'calc(100vh - 68px)',
                                    marginTop: '58px'
                                }}
                            >
                                <AnimatePresence mode="wait">
                                    <motion.div
                                        key="dashboard"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.5 }}
                                        className="w-full h-full"
                                    >
                                        <Suspense fallback={null}>
                                            <ProjectDashboard />
                                            <SubscribeModal />
                                        </Suspense>
                                    </motion.div>
                                </AnimatePresence>
                            </div>
                        )}
                    </section>
                </main>
                <RequestModal />
                <LoginToContinue />
            </div>

            {/* Hidden preload component when tempProjectId is set but not yet navigated */}
            {tempProjectId && !projectId && (
                <div style={{ display: 'none' }}>
                    <Suspense fallback={null}>
                        <ProjectDashboard />
                    </Suspense>
                </div>
            )}
        </>
    );
};

export default HeroExperience;
