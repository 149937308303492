import React from 'react';
import YouTubeEmbed from '../Components/YouTubeEmbed';
import { motion } from 'framer-motion';

/**
 * Demo video section component for the landing page
 * @returns {JSX.Element} Demo video section with heading and YouTube embed
 */
const DemoVideoSection = () => {
    // YouTube video ID extracted from the URL: https://www.youtube.com/watch?v=xgy6sAaYyzE
    const videoId = 'xgy6sAaYyzE';

    return (
        <section className="relative py-28 px-4">
            {/* Background gradient */}
            <div className="absolute inset-0 bg-gradient-to-b from-black via-indigo-950/20 to-black opacity-60 pointer-events-none" />

            {/* Decorative elements */}
            <div className="absolute top-20 left-10 w-40 h-40 bg-indigo-500/10 rounded-full blur-3xl pointer-events-none" />
            <div className="absolute bottom-20 right-10 w-60 h-60 bg-purple-500/10 rounded-full blur-3xl pointer-events-none" />

            <div className="max-w-7xl mx-auto relative">
                <motion.div
                    className="text-center mb-16"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                >
                    <h2 className="text-4xl sm:text-5xl md:text-6xl font-bold tracking-tight mb-6 bg-clip-text text-transparent bg-gradient-to-r from-indigo-200 via-blue-300 to-purple-200">
                        See pre.dev in Action
                    </h2>
                    <p className="text-xl md:text-2xl text-zinc-400 max-w-2xl mx-auto leading-relaxed">
                        Watch our demo video to see how pre.dev instantly
                        generates functional prototypes from your product ideas
                    </p>
                </motion.div>

                <motion.div
                    className="w-full max-w-5xl mx-auto relative group"
                    initial={{ opacity: 0, scale: 0.95 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.7, delay: 0.2 }}
                >
                    {/* Video frame */}
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 to-purple-600 rounded-xl blur opacity-40 group-hover:opacity-75 transition duration-500"></div>

                    {/* MacOS style window frame */}
                    <div className="relative bg-zinc-900 rounded-xl p-1 shadow-2xl">
                        <YouTubeEmbed
                            videoId={videoId}
                            title="pre.dev Demo Video: How to Use pre.dev"
                            className="rounded-b-lg overflow-hidden"
                        />
                    </div>
                </motion.div>
            </div>
        </section>
    );
};

export default DemoVideoSection;
